import { useMemo, useState, type JSX, useContext } from 'react';

import { ThemeContext } from 'context/theme';
import { getIsDarkTheme } from 'utils/storage';
import { RadioButton } from 'components/radio-button';

import * as Styles from './styles';
import { ChoiceThemeProps } from './types';
import { radioButtonsByTheme } from './config';

const ChoiceTheme = ({
  padding,
  border = false,
}: ChoiceThemeProps): JSX.Element => {
  const isDark = getIsDarkTheme();
  const { selectTheme } = useContext(ThemeContext);
  const [isCurrentTheme, setIsCurrentTheme] = useState(isDark);

  const selectedTheme = useMemo(() => {
    if (isCurrentTheme) {
      return radioButtonsByTheme[0];
    }
    if (isCurrentTheme === false) {
      return radioButtonsByTheme[1];
    }
    return radioButtonsByTheme[2];
  }, [isCurrentTheme]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let mode;
    if (value === 'dark') {
      mode = true;
    }
    if (value === 'light') {
      mode = false;
    }
    if (value === 'system') {
      mode = undefined;
    }

    if (selectTheme) {
      selectTheme(mode);
      setIsCurrentTheme(mode);
    }
  };

  return (
    <Styles.Container border={border} padding={padding}>
      <Styles.Title>Appearance</Styles.Title>

      <div>
        {radioButtonsByTheme.map((radio) => (
          <RadioButton
            name="theme"
            key={radio.label}
            label={radio.label}
            value={radio.value}
            onChange={onChange}
            component={radio.circle}
            checked={radio.value === selectedTheme?.value}
            style={{
              width: '100%',
              height: '24px',
              border: 'none',
              padding: '0 3px',
              background: 'none',
            }}
          />
        ))}
      </div>
    </Styles.Container>
  );
};

export { ChoiceTheme };
