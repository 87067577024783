import { useField, FormikValues, useFormikContext } from 'formik';
import { ReactGoogleAutocompleteProps } from 'react-google-autocomplete';

import { api } from 'api';
import { CommonFieldProps } from 'types';
import { controlError } from 'utils/error';
import {
  AddressAutoCompleteInput,
  AddressAutocompleteInputProps,
} from 'components/address-autocomplete-input';

export interface AddressAutoCompleteInputFieldProps
  extends AddressAutocompleteInputProps,
    Partial<CommonFieldProps> {
  zipFieldName?: string;
  cityFieldName?: string;
  stateFieldName?: string;
  isAddressAutoFill?: boolean;
  onTaxSelected?: (zip: string, tax: string) => void;
}

export const AddressAutoCompleteInputField = ({
  onTaxSelected,
  name = 'address',
  label = 'Address',
  zipFieldName = 'zip',
  cityFieldName = 'city',
  stateFieldName = 'state',
  isAddressAutoFill = true,
  controlErrorLabel = true,
  placeholder = 'Add address',
  ...props
}: AddressAutoCompleteInputFieldProps) => {
  const [field, meta, { setValue }] = useField(name);
  const { setFieldValue } = useFormikContext<FormikValues>();

  const error = controlError(meta, name, label, controlErrorLabel);

  const onPlaceSelected: ReactGoogleAutocompleteProps['onPlaceSelected'] =
    async (place) => {
      setValue(place.formatted_address);

      const onAutoFill = (fieldName: string, value: string) => {
        if (isAddressAutoFill) {
          setFieldValue(fieldName, value);
        }
      };

      const handleComponentAutoFill = (
        type: string,
        fieldName: string,
        callback?: (value: string) => void
      ) => {
        const placeComponent = place.address_components?.find(
          (component: { types: string[] }) => component.types.includes(type)
        );

        const value = placeComponent?.short_name || '';
        if (value) {
          onAutoFill(fieldName, value);
          callback?.(value);
        }
      };

      handleComponentAutoFill('administrative_area_level_1', stateFieldName);

      handleComponentAutoFill('locality', cityFieldName);

      handleComponentAutoFill('postal_code', zipFieldName, async (zip) => {
        if (onTaxSelected) {
          try {
            const { data: taxRate } = await api.tax.getTaxByZipcode({ zip });
            onTaxSelected(
              zip,
              (Number(taxRate?.[0]?.total_rate) * 100).toFixed(2)
            );
          } catch (zipError) {
            console.error(zipError);
          }
        }
      });
    };

  return (
    <AddressAutoCompleteInput
      error={error}
      label={label}
      placeholder={placeholder}
      onPlaceSelected={onPlaceSelected}
      {...field}
      {...props}
    />
  );
};
