import { useParams } from 'react-router-dom';

import { Messages } from 'config';
import { Icon } from 'components/icon';
import { useAlert, handleMutation } from 'hooks';
import { PayoutType, ButtonColors } from 'types';
import { useCreateMutation } from 'store/api/payout';
import { Dialog, DialogType } from 'components/dialog';
import { Button, ButtonTypes } from 'components/button';
import { PaymentsForm } from 'components/payments-form';

import * as Styles from './styles';
import { FormValues, AddRowDialogProps } from './types';
import { validationSchema, getInitialValues } from './config';

export const AddRowDialog = ({
  isDeduction,
  setSelectedValues,
  ...props
}: AddRowDialogProps) => {
  const { id } = useParams();
  const { showSuccessAlert } = useAlert();

  const type = isDeduction ? PayoutType.Advance : PayoutType.Bonus;
  const title = `Add ${type}`;

  const [create, mutation] = useCreateMutation();
  handleMutation(mutation);

  return (
    <Dialog
      {...props}
      title={title}
      trigger={
        <Styles.AddRowButton
          transparent
          padding="0"
          text="Add row"
          icon={<Icon.Plus />}
          colorType={ButtonColors.LightBlue}
        />
      }
    >
      {(close, _, ButtonWrap) => (
        <PaymentsForm<FormValues>
          isDialog
          hideBackButton
          validationSchema={validationSchema}
          initialValues={getInitialValues(isDeduction)}
          footerButtons={({ dirty, isSubmitting }) => (
            <ButtonWrap type={DialogType.Plain}>
              <Button
                text="Cancel"
                onClick={close}
                colorType={ButtonColors.LightBlue}
              />
              <Button
                text="Create"
                type={ButtonTypes.Submit}
                disabled={!dirty || isSubmitting}
              />
            </ButtonWrap>
          )}
          onSubmit={async (values) => {
            const response = await create({
              ...values,
              employeeId: Number(id),
              amount: +values.amount,
            }).unwrap();

            if (response.id) {
              showSuccessAlert(`${type} ${Messages.ADDED_SUCCESSFULLY}`);
              setSelectedValues?.((prevValues: number[]) => [
                ...prevValues,
                response.id,
              ]);
              close();
            }
          }}
        />
      )}
    </Dialog>
  );
};
