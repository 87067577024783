import { useState, type JSX, useEffect, useCallback } from 'react';

import { SlidePanel } from 'components/slide-panel';
import { Button, ButtonTypes } from 'components/button';
import { ResetButton } from 'components/specified-buttons';
import { ButtonColors, ButtonStyleTypes } from 'types/enums';
import {
  isMultiSelectValue,
  isSingleSelectValue,
  areSomeFiltersApplied,
} from 'utils/helpers';
import {
  FilterSection,
  FilterSectionType,
  FilterSectionOnChangeValue,
} from 'components/filter-section';

import { FilterProps, SectionsProps, FilterRenderProps } from './types';

const Filter = function <T extends Record<string, any>>({
  isOpen,
  values,
  errors,
  onApply,
  onClose,
  children,
  sections,
  isApplied,
  onResetAll,
  initialValues,
  title = 'Filters',
}: FilterProps<T>): JSX.Element {
  const [filterValues, setFilterValues] = useState(values);

  useEffect(() => {
    setFilterValues(values);
  }, [values]);

  const handleApply = () => {
    onApply(filterValues);
    if (onClose) {
      onClose();
    }
  };

  const toggleFilter = useCallback(
    (value: string, key: keyof typeof filterValues) => {
      const copyFilterValues = new Set(filterValues[key]);

      if (copyFilterValues.has(value)) {
        copyFilterValues.delete(value);
      } else {
        copyFilterValues.add(value);
      }

      setFilterValues((prev) => ({
        ...prev,
        [key]: copyFilterValues,
      }));
    },
    [filterValues]
  );

  const handleReset = useCallback(() => {
    onResetAll?.();
    const copyState = { ...filterValues };
    Object.entries(copyState).forEach(([key, value]) =>
      value instanceof Set
        ? value.clear()
        : (copyState[key as keyof typeof filterValues] = initialValues?.[key])
    );

    setFilterValues(copyState);
  }, [filterValues]);

  const setFilter: FilterRenderProps<T>['setFilter'] = useCallback(
    (value, key) => {
      setFilterValues((prev) => {
        if (typeof key === 'string' && key.includes('.')) {
          const nestedField = key
            .split('.')
            .reverse()
            .reduce<Record<string, any>>(
              (obj, fieldName, currentIndex, array) => {
                if (currentIndex === 0) {
                  obj[fieldName] = value;
                } else if (currentIndex === array.length - 1) {
                  obj = {
                    [fieldName]: {
                      ...prev[fieldName],
                      ...obj,
                    },
                  };
                } else {
                  obj = {
                    [fieldName]: obj,
                  };
                }

                return obj;
              },
              {}
            );

          return {
            ...prev,
            ...nestedField,
          };
        }

        return {
          ...prev,
          [key]: value,
        };
      });
    },
    [filterValues]
  );

  const onFilterSectionChange = (
    value: FilterSectionOnChangeValue,
    name: SectionsProps<T>['name'],
    type: SectionsProps<T>['type'],
    setOnlyValue: boolean = true
  ) => {
    if (type === FilterSectionType.Checkbox && typeof value === 'string') {
      toggleFilter(value, name);
    } else if (type === FilterSectionType.Select && setOnlyValue) {
      setFilter(
        isMultiSelectValue(value)
          ? value.map((option) => option.value)
          : isSingleSelectValue(value)
            ? value?.value
            : '',
        name
      );
    } else {
      setFilter(value, name);
    }
  };

  const resetFilter = (name: keyof T) => setFilter(initialValues?.[name], name);

  return (
    <SlidePanel
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      contentPadding="0 0 8px 0"
      topRightSlot={
        <ResetButton
          text="Reset all"
          onClick={handleReset}
          disabled={
            !(typeof isApplied === 'boolean'
              ? isApplied
              : areSomeFiltersApplied(filterValues))
          }
        />
      }
      actions={
        <Button
          fullWidth
          text="Apply"
          disabled={!!errors}
          onClick={handleApply}
          type={ButtonTypes.Button}
          colorType={ButtonColors.Blue}
          styleType={ButtonStyleTypes.Standard}
        />
      }
    >
      {sections?.map(
        (
          {
            name,
            selectProps,
            hasAccess = true,
            title: sectionTitle,
            type = FilterSectionType.Checkbox,
            ...rest
          },
          index
        ) =>
          hasAccess && (
            <FilterSection
              key={index}
              type={type}
              title={sectionTitle}
              error={errors?.[name]}
              selectProps={selectProps}
              value={filterValues[name]}
              onReset={() => resetFilter(name)}
              onChange={(v) =>
                onFilterSectionChange(v, name, type, selectProps?.onlyValue)
              }
              {...rest}
            />
          )
      )}
      {children?.({ errors, setFilter, resetFilter, filterValues })}
    </SlidePanel>
  );
};

export { Filter };
export type { FilterProps, FilterRenderProps };
