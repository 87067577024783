import { components, ControlProps } from 'react-select';

import { isMobile } from 'hooks';

import { Search } from './styles';

type Props = ControlProps<any>;

export const Control = ({ children, ...props }: Props) => {
  const mobile = isMobile();

  return components.Control({
    ...props,
    children: (
      <>
        {props.selectProps.isSearchable && !mobile && <Search />}
        {children}
      </>
    ),
  });
};
