import utc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday';
import timezone from 'dayjs/plugin/timezone';
import dayjs, { Dayjs, ConfigType } from 'dayjs';
import isYesterday from 'dayjs/plugin/isYesterday';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormatPlugin from 'dayjs/plugin/advancedFormat';
import duration, { DurationUnitType } from 'dayjs/plugin/duration';

import { joinStrings } from './helpers';

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(isYesterday);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormatPlugin);

/**
 * Formats a given date into a specified format.
 * @param {ConfigType} date - The date to format.
 * @param {string} [format='L'] - The format string (default is 'L').
 * @returns {string} The formatted date string.
 */
export const formatDate = (date: ConfigType, format: string = 'L'): string => {
  return dayjs(date).format(format);
};

/**
 * Formats a date according to a specified timezone.
 * @param {ConfigType} date - The date to format.
 * @param {string} [timeZone] - The timezone to use for formatting.
 * @param {string} [template='L'] - The format template (default is 'L').
 * @returns {string} The formatted date string in the specified timezone.
 */
export const formatTimezoneSpecific = (
  date: ConfigType,
  timeZone?: string,
  template: string = 'L'
): string => {
  const isStringNumber = typeof date === 'string' && /^\d+$/.test(date);
  const dayjsValue = dayjs(isStringNumber ? Number(date) : date);

  return (timeZone ? dayjsValue.tz(timeZone) : dayjsValue).format(template);
};

/**
 * Formats a timezone code into a more readable format.
 * @param {string} value - The timezone code to format.
 * @returns {string} The formatted timezone label.
 */
export const formatTimezoneCode = (value: string) => {
  const parts = value.split('/');
  const partsCount = parts.length;
  if (partsCount < 2) {
    return value;
  }
  return `${value.split('/')[partsCount - 1].replaceAll('_', ' ')} (${value})`;
};

/**
 * Gets a list of supported timezone options.
 * @returns {Array<{ value: string, label: string }>} An array of objects containing timezone values and labels.
 */
export const getTimezoneOptions = () =>
  Intl.supportedValuesOf('timeZone')
    .map((value) => ({
      value,
      label: formatTimezoneCode(value),
    }))
    .sort((a, b) => {
      const isAmericaA = a.value.startsWith('America');
      const isAmericaB = b.value.startsWith('America');
      if (isAmericaA && isAmericaB) {
        return a.label.localeCompare(a.label);
      }
      if (isAmericaA) {
        return -1;
      }
      if (isAmericaB) {
        return 1;
      }
      return a.value.localeCompare(b.value);
    });

/**
 * Gets a human-readable representation of the time for a given date.
 * @param {number | Dayjs} data - The timestamp or Dayjs object to evaluate.
 * @param {string} [oldFormat='MM/DD/YY'] - The fallback format if not today or yesterday.
 * @returns {string} A string representing the time in a human-readable format.
 */
export const getCalendarTime = (
  data: number | Dayjs,
  oldFormat = 'MM/DD/YY'
) => {
  const current = typeof data === 'number' ? dayjs.unix(data) : data;

  if (current.isToday()) {
    return 'Today';
  }
  if (current.isYesterday()) {
    return 'Yesterday';
  }

  const durationTime = Math.abs(dayjs().diff(current, 'day'));

  return current.format(durationTime <= 7 ? 'dddd' : oldFormat);
};

/**
 * Formats a date using an advanced template that includes today/yesterday indicators.
 * @param {string | Dayjs} date - The date to format.
 * @returns {string} The formatted date string with advanced formatting.
 */
export const advancedFormat = (date: string | Dayjs): string => {
  const dayjsObj = dayjs(date);
  const isDateToday = dayjsObj.isToday();
  const isDateYesterday = dayjsObj.isYesterday();

  const template = `${
    isDateToday ? '[Today], ' : isDateYesterday ? '[Yesterday], ' : ''
  }D MMMM - hh:mm a (z)`;

  return dayjsObj.format(template);
};

/**
 * Calculates the duration between a start date and an optional end date in specified units.
 * @param {number} date - The start timestamp in milliseconds.
 * @param {DurationUnitType} [unit='second'] - The unit of duration to return (default is seconds).
 * @param {number} [finished] - An optional end timestamp in milliseconds.
 * @returns {number} The duration between the start and end dates in the specified unit.
 */
export const getDuration = (
  date: number,
  unit: DurationUnitType = 'second',
  finished?: number
): number => {
  const now = Date.now();
  const isEnded = finished && finished <= now;
  const durationValue = Math.max(isEnded ? finished - date : now - date, 0);
  const value = dayjs.duration(durationValue, 'millisecond');
  return value.as(unit);
};

/**
 * Formats a numerical value as two digits (e.g., "05" instead of "5").
 * @param {number} value - The numerical value to format.
 * @returns {string} The formatted two-digit string.
 */
export const getTimeValue = (value: number) => {
  return value < 10 ? `0${value}` : value;
};

/**
 * Formats a duration into a human-readable string based on the provided format function.
 * @param {number} value - The duration value in milliseconds or specified unit.
 * @param {(duration: duration.Duration) => string} [format] - A custom formatting function for the duration.
 * @param {DurationUnitType} [unit='second'] - The unit of duration used for input value (default is seconds).
 * @returns {string} The formatted duration string.
 */
export const formatDuration = (
  value: number,
  format: (duration: duration.Duration) => string = (durationValue) => {
    const minutes = Math.floor(durationValue.asMinutes());
    const seconds = durationValue.seconds();

    return `${getTimeValue(minutes)}:${getTimeValue(seconds)}`;
  },
  unit: DurationUnitType = 'second'
) => {
  return format(dayjs.duration(value, unit));
};

/**
 * Calculates the age based on the difference between now and a given timestamp in milliseconds.
 * @param {string | number | null} [milliseconds] - The timestamp in milliseconds or null to indicate no age available.
 * @returns {[number, number, number]} An array containing years, months, and days representing the age.
 */
export const getAge = (milliseconds?: string | number | null) => {
  if (!milliseconds) {
    return [];
  }
  const age = dayjs.duration(Date.now() - Number(milliseconds));

  const years = age.years();
  const months = age.months();
  const days = age.days();

  return [years, months, days];
};

/**
 * Formats an age represented in milliseconds into a human-readable string with appropriate units.
 * @param {string | null} [value] - The timestamp in milliseconds or null to indicate no age available.
 * @param {Parameters<typeof joinStrings>[2]} [placeholder] - Optional placeholder for joining components when undefined values are present.
 * @returns {string} A formatted age string such as "2y 3m".
 */
export const getFormattedAge = (
  value?: string | null,
  placeholder?: Parameters<typeof joinStrings>[2]
) => {
  const units = getAge(value);
  const suffixes = ['y', 'm', 'd'];

  const components = units.map((unit, index) =>
    !unit ? undefined : `${unit}${suffixes[index]}`
  );

  return joinStrings(components, ' ', placeholder);
};

/**
 * Formats time data into a specified time format based on the current day's start time.
 * @param {number} value - Time in milliseconds to be added to the start of the current day.
 * @param {string} [format='h:mm a'] - Format template for output time.
 * @returns {string} Formatted time as per given template.
 */
export const formatTimeData = (value: number, format: string = 'h:mm a') => {
  const currentDay = dayjs().startOf('d');
  return currentDay.add(dayjs.duration(value, 'ms')).format(format);
};

/**
 * Calculates the difference in milliseconds from the start of the day for a given date.
 * @param {number | string | Date | Dayjs} date - Input date which can be various types.
 * @returns {number} Milliseconds elapsed since start of the day.
 */
export const getTimeData = (date: number | string | Date | Dayjs) => {
  const currentDay = dayjs(date);
  const startOfDay = currentDay.startOf('d');
  return dayjs.duration(currentDay.diff(startOfDay)).asMilliseconds();
};

/**
 * Calculates the week number of the month for a given date.
 * @param {Date} date - Input date for which week number needs to be calculated.
 * @returns {number} Week number within the month.
 */
export const getNumberOfWeek = (date: Date) => {
  const day = dayjs(date);
  const dayOfMonth = day.date();
  const weekNumber = Math.ceil(dayOfMonth / 7);
  return weekNumber;
};

/**
 * Converts a Date object to an ISO string adjusted for local timezone offset.
 * @param {Date} date - Input Date object to convert.
 * @returns {string} ISO formatted string representing local time.
 */
export const toLocaleISOString = (date: Date) =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
