import { ButtonColors } from 'types';
import { Icon } from 'components/icon';
import { Button } from 'components/button';
import { extractFileNameAndType } from 'utils/helpers';

import { DownloadButtonProps } from './types';

export const DownloadButton = ({
  url,
  onDownloadFile,
  ...props
}: DownloadButtonProps) =>
  url && (
    <Button
      padding="0"
      transparent
      icon={<Icon.Download />}
      tooltip="Download Receipt"
      colorType={ButtonColors.LightBlue}
      onClick={() => onDownloadFile(url, extractFileNameAndType(url).name)}
      {...props}
    />
  );
