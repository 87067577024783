import { useCallback } from 'react';
import { NavigateOptions } from 'react-router-dom';

import { addParamsToUrl } from 'utils/query';

import { useNavigate } from './useNavigation';

export type QueryNavigateFunction = (
  url: string | number,
  options?: Options
) => void;

export interface Options extends NavigateOptions {
  query?: Record<string, string>;
}

export const useQueryNavigate = () => {
  const navigate = useNavigate();

  const navigateParams: QueryNavigateFunction = useCallback(
    (url, options) => {
      if (typeof url === 'number') {
        navigate(url);
      } else {
        const { query, ...opts } = options ?? {};
        const oldSearch = window.location.search;
        const params = new URLSearchParams(oldSearch).entries();
        const newUrl = addParamsToUrl(url, {
          ...Object.fromEntries(params),
          ...query,
        });
        navigate(newUrl, opts);
      }
    },
    [navigate]
  );

  return navigateParams;
};
