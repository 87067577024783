import { ReactNode } from 'react';

import { PopupProps } from '../popup';
import { TextColor, ButtonColors } from '../../types';

import { Text, ButtonsWrapper } from './styles';

export enum DialogType {
  Plain,
  Complex,
}

export interface DialogProps extends Omit<PopupProps, 'children'> {
  icon?: ReactNode;
  buttons?: Buttons;
  type?: DialogType;
  cancelText?: string;
  confirmText?: string;
  iconColor?: TextColor;
  onConfirm?: () => void;
  closeOnConfirm?: boolean;
  showCloseButton?: boolean;
  text?: string | ReactNode;
  cancelStyle?: ButtonColors;
  title?: string | ReactNode;
  confirmStyle?: ButtonColors;
  children?: (
    close: () => void,
    TextComponent: typeof Text,
    ButtonsComponent: typeof ButtonsWrapper
  ) => ReactNode;
}

export interface DialogTypeProps {
  type: DialogType;
}

type Buttons = (close: () => void) => ReactNode;
