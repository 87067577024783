import type { JSX } from 'react';

import { Popup } from 'components/popup';
import { EmptyPlaceholder } from 'config';
import { EllipsisText } from 'components/ellipsis-text';

import * as Styles from './styles';
import { ListWithShowMoreProps } from './types';

const ListWithShowMore = ({
  value,
  firstElementStyle,
  ...popupProps
}: ListWithShowMoreProps): JSX.Element => {
  if (!value || !value?.length) {
    return <>{EmptyPlaceholder}</>;
  }

  const [first, ...rest] = value;

  const firstElement =
    typeof first === 'string' ? (
      <EllipsisText style={firstElementStyle}>{first}</EllipsisText>
    ) : (
      first
    );

  if (value?.length === 1) {
    return firstElement;
  }

  const options = rest?.map((item, idx) => (
    <div key={`${item}-${idx}`}>{item}</div>
  ));

  return (
    <Styles.Container>
      {firstElement}

      <Popup
        position={['bottom right', 'bottom center', 'bottom left']}
        trigger={<Styles.Title>{`+ ${rest?.length} more`}</Styles.Title>}
        {...popupProps}
      >
        <Styles.Options>{options}</Styles.Options>
      </Popup>
    </Styles.Container>
  );
};

export { ListWithShowMore };
