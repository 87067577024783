import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { addParamsToUrl } from 'utils/query';
import {
  providesList,
  cacheByIdArg,
  invalidatesList,
  cacheByIdArgProperty,
} from 'utils/query-cache';

import { apiQuery } from '../../query';

import {
  Appointment,
  EditAppointment,
  CreateAppointment,
  AppointmentsQuery,
} from './types';

const tagType = 'Appointments';

export const appointmentsApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'appointmentsApi',
  endpoints: (build) => ({
    get: build.query<Appointment, string>({
      providesTags: cacheByIdArg(tagType),
      query: (id) => ({
        method: 'get',
        url: URL.APPOINTMENT.replace(':id', String(id)),
      }),
    }),

    delete: build.mutation<void, string>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'delete',
        url: URL.APPOINTMENT.replace(':id', id),
      }),
    }),

    create: build.mutation<void, CreateAppointment>({
      invalidatesTags: invalidatesList(tagType),
      query: (data) => ({
        data,
        method: 'post',
        url: URL.APPOINTMENTS,
      }),
    }),

    list: build.query<Appointment[], AppointmentsQuery>({
      providesTags: providesList(tagType),
      query: (query) => ({
        method: 'get',
        url: addParamsToUrl(URL.APPOINTMENTS, query),
      }),
    }),

    edit: build.mutation<void, EditAppointment>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: URL.APPOINTMENT.replace(':id', String(id)),
      }),
    }),
  }),
});

export const {
  useGetQuery,
  useListQuery,
  useEditMutation,
  useCreateMutation,
  useDeleteMutation,
} = appointmentsApi;

export type { Appointment, EditAppointment, CreateAppointment };
