import { type JSX, PropsWithChildren } from 'react';

import { ColWrap } from './styles';
import { ColProps } from './types';

export const Col = ({
  innerRef,
  col = 12,
  colTablet = 12,
  colMobile = 12,
  ...props
}: PropsWithChildren<ColProps>): JSX.Element => {
  return (
    <ColWrap
      col={col}
      colTablet={colTablet}
      colMobile={colMobile}
      {...props}
      ref={innerRef}
    />
  );
};
