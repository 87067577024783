import { useMemo } from 'react';

import { Messages } from 'config';
import { mapQueries } from 'utils/query';
import { capitalize } from 'utils/string';
import { useExpensesQuery } from 'store/api/accounting';
import { formatPrice, joinStrings } from 'utils/helpers';
import { useListQuery, useDeleteMutation } from 'store/api/event-expenses';
import {
  isMobile,
  handleMutation,
  useSearchQuery,
  useDownloadFile,
} from 'hooks';
import {
  CashCardType,
  EventExpense,
  ListCardStyle,
  AccountingExpense,
} from 'types';
import {
  List,
  Flex,
  Loader,
  ListCard,
  ListHeader,
  DeleteDialog,
  DownloadButton,
} from 'components';

import { ExpensesProps } from '../types';
import { CashCards } from '../cash-cards';

import * as Styles from './styles';
import { EditExpense, CreateExpense } from './form';

const template = '1fr 120px 1fr 1fr 1fr 104px';
const titles = ['Category', 'Amount', 'Payment Method', 'Notes', 'Created by'];

const isEventExpense = (
  expense: EventExpense | AccountingExpense
): expense is AccountingExpense => {
  return 'categories' in expense;
};

export const Expenses = ({ data, event }: ExpensesProps) => {
  const mobile = isMobile();
  const { debounceQuery, ...search } = useSearchQuery();
  const commonSearch = { search: debounceQuery };

  const { data: expenses, ...props } = useListQuery(commonSearch);
  const { data: accountingExpenses, ...accountingProps } = useExpensesQuery({
    ...commonSearch,
    eventIds: JSON.stringify(event.id),
  });

  const { downloadFile } = useDownloadFile();

  const currentExpenses = [...(expenses || []), ...(accountingExpenses || [])];

  const totalOut = useMemo(
    () => currentExpenses?.reduce((acc, value) => acc + +value.amount, 0),
    [currentExpenses]
  );

  const [remove, mutation] = useDeleteMutation();
  handleMutation({
    ...mutation,
    successMessage: `Expense ${Messages.DELETED_SUCCESSFULLY}`,
  });

  return (
    <Loader isFullScreen={false} isLoading={props.isLoading}>
      <Flex gap={24} fullHeight flexDirection={mobile ? 'column' : 'row'}>
        <CashCards
          data={data}
          showOnlyCash
          eventId={event.id}
          totalOut={totalOut || 0}
          customTotalCash={totalOut}
          customType={CashCardType.Expenses}
        />

        <Flex gap={24} fullWidth fullHeight flexDirection="column">
          <List
            {...mapQueries([props, accountingProps])}
            {...search}
            count={currentExpenses?.length}
            mainWrapperStyles={{ padding: 0 }}
            searchBarRightSlot={<CreateExpense />}
            searchContainerStyle={{ margin: '16px 0' }}
            header={
              <ListHeader padding="8px 0" titles={titles} template={template} />
            }
            searchInputProps={{
              placeholder:
                'Search category, payment method, amount, notes or created by',
            }}
            footer={
              <ListCard
                selected
                padding="16px"
                template={template}
                even={ListCardStyle.Green}
                values={[
                  <p className="semibold">Total</p>,
                  formatPrice(totalOut),
                ]}
              />
            }
          >
            {currentExpenses?.map((value) => {
              const amount = formatPrice(value.amount);

              const downloadReceipt = value?.receiptUrl && (
                <DownloadButton
                  url={value.receiptUrl}
                  onDownloadFile={downloadFile}
                />
              );

              const amountComponent = (
                <Styles.Amount isNegative={+value.amount < 0}>
                  {amount}
                </Styles.Amount>
              );

              return (
                <ListCard
                  key={value.id}
                  padding="16px"
                  template={template}
                  values={
                    isEventExpense(value)
                      ? [
                          joinStrings(
                            value.categories.map((category) => category.name),
                            ','
                          ),
                          amountComponent,
                          capitalize(value.paymentMethod),
                          value?.description,
                          joinStrings(
                            value.employees.map(
                              (employee) => employee?.user?.name
                            ),
                            ','
                          ),
                          value?.receiptUrl && (
                            <Flex justifyContent="flex-end">
                              {downloadReceipt}
                            </Flex>
                          ),
                        ]
                      : [
                          value?.category?.name,
                          amountComponent,
                          value?.paymentMethod?.name,
                          value?.notes,
                          value?.user?.name,
                          <Flex gap={16} justifyContent="flex-end">
                            {value?.receiptUrl && downloadReceipt}
                            {!isEventExpense(value) && (
                              <>
                                <EditExpense data={value} />
                                <DeleteDialog
                                  id={value.id}
                                  action={remove}
                                  title="Delete Expense"
                                  name={`expense with ${amount}`}
                                />
                              </>
                            )}
                          </Flex>,
                        ]
                  }
                />
              );
            })}
          </List>
        </Flex>
      </Flex>
    </Loader>
  );
};
