import { LinkProps } from 'react-router-dom';
import {
  type JSX,
  ForwardedRef,
  CSSProperties,
  ButtonHTMLAttributes,
} from 'react';

import { ButtonColors, ButtonStyleTypes } from 'types';

export enum ButtonTypes {
  Submit = 'submit',
  Button = 'button',
  Reset = 'reset',
}

export interface ButtonProps
  extends Partial<Pick<LinkProps, 'to' | 'target'>>,
    ButtonHTMLAttributes<HTMLButtonElement>,
    BaseButtonProps {
  text?: string;
  testId?: string;
  tooltip?: string;
  disabled?: boolean;
  type?: ButtonTypes;
  withBorder?: boolean;
  style?: CSSProperties;
  isBlackTooltip?: boolean;
  stopPropagation?: boolean;
  originalColorIcon?: boolean;
  innerRef?: ForwardedRef<HTMLButtonElement | HTMLAnchorElement>;
  alignItems?:
    | 'center'
    | 'inherit'
    | 'initial'
    | 'stretch'
    | 'baseline'
    | 'flex-end'
    | 'flex-start';
  justifyContent?:
    | 'center'
    | 'inherit'
    | 'initial'
    | 'flex-end'
    | 'flex-start'
    | 'space-around'
    | 'space-between';
}

export interface ButtonStyleProps {
  $colorType: ButtonColors;
  $styleType: ButtonStyleTypes;
  $weight?: ButtonProps['weight'];
  $padding: ButtonProps['padding'];
  $fullWidth?: ButtonProps['fullWidth'];
  $alignItems?: ButtonProps['alignItems'];
  $transparent: ButtonProps['transparent'];
  $defaultPrimary?: ButtonProps['defaultPrimary'];
  $justifyContent?: ButtonProps['justifyContent'];
  $nativeColorText: ButtonProps['nativeColorText'];
  $nativeColorIcon?: ButtonProps['nativeColorIcon'];
  $positionRightIcon?: ButtonProps['positionRightIcon'];
}

export interface BaseButtonProps {
  weight?: number;
  padding?: string;
  icon?: JSX.Element;
  fullWidth?: boolean;
  transparent?: boolean;
  rightIcon?: JSX.Element;
  colorType?: ButtonColors;
  defaultPrimary?: boolean;
  nativeColorIcon?: boolean;
  nativeColorText?: boolean;
  positionRightIcon?: boolean;
  styleType?: ButtonStyleTypes;
}
