import styled from 'styled-components';

export const Container = styled.div`
  gap: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 0;
  overflow-x: auto;
  flex-direction: ${({ theme }) =>
    theme.responsive.isMobile ? 'column' : 'row'};
`;

export const SalesContainer = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  min-width: ${({ theme }) => (!theme.responsive.isDesktop ? '100%' : '648px')};
`;
