import { useMemo, type JSX } from 'react';

import { isMobile } from 'hooks';
import { Popup } from 'components/popup';
import { ButtonColors } from 'types/enums';
import { Button } from 'components/button';
import { CloseButton } from 'components/specified-buttons';

import * as Styles from './styles';
import { DialogType, DialogProps } from './types';

const Dialog = ({
  icon,
  type,
  text,
  title,
  buttons,
  children,
  onConfirm,
  iconColor,
  showCloseButton,
  width = '400px',
  closeOnConfirm = true,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  confirmStyle = ButtonColors.Red,
  cancelStyle = ButtonColors.LightBlue,
  ...props
}: DialogProps): JSX.Element => {
  const mobile = isMobile();

  const computedType = useMemo(() => {
    if (type !== undefined) {
      return type;
    }
    return buttons ? DialogType.Complex : DialogType.Plain;
  }, [buttons, type]);

  return (
    <Popup modal width={mobile ? '90vw' : width} {...props}>
      {(close: () => void) => {
        return (
          <Styles.Container type={computedType}>
            {icon && <Styles.Icon color={iconColor}>{icon}</Styles.Icon>}

            {title && (
              <Styles.Header type={computedType}>
                {title}
                {showCloseButton && <CloseButton onClose={close} />}
              </Styles.Header>
            )}

            {children ? (
              <>{children(close, Styles.Text, Styles.ButtonsWrapper)}</>
            ) : (
              <>
                {text && <Styles.Text type={computedType}>{text}</Styles.Text>}

                <Styles.ButtonsWrapper type={computedType}>
                  {buttons ? (
                    buttons(close)
                  ) : (
                    <>
                      <Button
                        fullWidth
                        onClick={close}
                        text={cancelText}
                        colorType={cancelStyle}
                      />
                      <Button
                        fullWidth
                        text={confirmText}
                        colorType={confirmStyle}
                        onClick={() => {
                          onConfirm?.();
                          if (closeOnConfirm) {
                            close();
                          }
                        }}
                      />
                    </>
                  )}
                </Styles.ButtonsWrapper>
              </>
            )}
          </Styles.Container>
        );
      }}
    </Popup>
  );
};

export { Dialog, DialogType };
export type { DialogProps };
