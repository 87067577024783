export const employeeSalesConfig = {
  mobileTemplate: '1fr 1fr',
  template: '2fr repeat(4, 1fr)',
  titles: ['Name', 'Gross', 'Net', 'Tips', 'Total'],
  cellsMobile: [
    { row: '1/2', col: '1/3' },
    { row: '2/3', col: '1/2' },
    { row: '2/3', col: '2/3' },
  ],
};

export const usersConfig = {
  mobileTemplate: '1fr 1fr',
  template: 'repeat(5, 1fr)',
  titles: ['User name', 'Sales Qt.', 'Advances', 'Reimbursements', 'Bonuses'],
  cellsMobile: [
    { row: '1/2', col: '1/3' },
    { row: '2/3', col: '1/2' },
    { row: '2/3', col: '2/3' },
  ],
};
