import type { JSX } from 'react';

import { ButtonColors } from 'types/enums';
import { Button } from 'components/button';

import * as Styles from './styles';
import { LogOutModalProps } from './types';

const LogOutModal = ({ onCancel, onLogOut }: LogOutModalProps): JSX.Element => {
  return (
    <Styles.ModalContainer>
      <h3>Sign out</h3>
      <p>Are you sure you want to sign out?</p>

      <Styles.Buttons>
        <Button
          fullWidth
          text="Cancel"
          onClick={onCancel}
          colorType={ButtonColors.LightBlue}
        />
        <Button fullWidth text="Sign out" onClick={onLogOut} />
      </Styles.Buttons>
    </Styles.ModalContainer>
  );
};

export { LogOutModal };
