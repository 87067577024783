import { IdObject, BadgeColor } from 'types';

import { Event } from '../event';
import { Employee } from '../employees';
import { Customer } from '../customers';

export enum RefundStatus {
  Deducted = 'deducted',
  NotYetDeducted = 'notYetDeducted',
}

export interface CommonEvent {
  date: string;
  event: string;
}

export const RefundStatusTitle = {
  [RefundStatus.Deducted]: 'Deducted',
  [RefundStatus.NotYetDeducted]: 'Not yet deducted',
};

export const RefundStatusColor = {
  [RefundStatus.Deducted]: BadgeColor.Green,
  [RefundStatus.NotYetDeducted]: BadgeColor.Red,
};

export interface Events {
  id: number;
  end: string;
  title: string;
  start: string;
  notes?: string;
  event?: Pick<Event, 'id' | 'name'>;
  employee?: Pick<Employee, 'id' | 'user'>;
  customer?: Pick<Customer, 'id' | 'lastName' | 'firstName'>;
}

export interface Refund extends CommonEvent, IdObject {
  tip?: number;
  saleAmount?: number;
  commission?: number;
  status: RefundStatus;
  refundAmount?: number;
}

export interface AllSalesEvent extends IdObject {
  date: string;
  check: number;
  event: string;
  amount: number;
  pdfUrl?: string;
  paymnetMethod?: string;
}

export interface AllSalesInfo {
  sales: number;
  comission: number;
  unpaidEvent: string;
  salesPerHour: number;
}

export interface Sales {
  info: AllSales;
  events?: Events[];
  refunds?: Refund[];
}

export interface AllSales {
  info: AllSalesInfo;
  sales: AllSalesEvent[];
}
