import * as Yup from 'yup';

import { PayoutType } from 'types';

import { FormValues } from './types';

export const getInitialValues = (isDeduction?: boolean): FormValues => ({
  date: '',
  notes: '',
  amount: '',
  eventId: undefined,
  type: isDeduction ? PayoutType.Advance : PayoutType.Bonus,
});

export const validationSchema = Yup.object().shape({
  date: Yup.string().required(),
  type: Yup.string().required(),
  eventId: Yup.string().required(),
  amount: Yup.number().required().positive(),
  notes: Yup.string().required().min(5).max(200),
});
