import { useRef, RefObject } from 'react';
import { useEventListener } from 'usehooks-ts';

export const useClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T | null>,
  callback?: (event: MouseEvent) => void
) => {
  const documentRef = useRef<Document>(document);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback?.(event);
    }
  };

  useEventListener('mousedown', handleClickOutside, documentRef);
};
