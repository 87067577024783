import { Messages } from 'config';
import { EventExpense } from 'types';
import { EditButtonWithRef } from 'components';
import { useAlert, handleMutation } from 'hooks';
import { useUpdateMutation } from 'store/api/event-expenses';

import { Form } from './form';

export const EditExpense = ({ data }: { data: EventExpense }) => {
  const { showSuccessAlert } = useAlert();
  const [update, mutation] = useUpdateMutation();

  handleMutation(mutation);

  return (
    <Form
      isEdit
      data={data}
      title="Edit Expense"
      trigger={<EditButtonWithRef />}
      onSubmit={async (values) => {
        const response = await update({
          ...values,
          id: data.id,
          amount: +values.amount,
          categoryId: +values.categoryId!,
          paymentMethodId: +values.paymentMethodId!,
        }).unwrap();

        if (response.id) {
          showSuccessAlert(`Expense ${Messages.UPDATED_SUCCESSFULLY}`);
        }
      }}
    />
  );
};
