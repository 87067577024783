import { useField } from 'formik';
import { useCallback } from 'react';

import { CommonFieldProps } from 'types';
import { controlError } from 'utils/error';

import { Input, InputProps } from '../input';

export interface InputFieldProps extends CommonFieldProps, InputProps {
  name: string;
  normalize?: (value: string, prevValue: string) => string | null | undefined;
}

export const InputField = ({
  name,
  label,
  normalize,
  controlErrorLabel = true,
  ...props
}: InputFieldProps) => {
  const [{ value, onChange, ...fieldProps }, meta, { setValue, setTouched }] =
    useField<string>(name);

  const error = controlError(meta, name, label, controlErrorLabel);
  const change = useCallback(
    async (newValue: string) => {
      await setValue(newValue, true);
    },
    [setValue]
  );

  return (
    <Input
      {...props}
      {...fieldProps}
      label={label}
      error={error}
      value={value ?? ''}
      onBlur={(e) => {
        if (e) {
          fieldProps.onBlur(e);
        } else {
          setTouched(true);
        }
      }}
      onChange={async (event) => {
        if (typeof event === 'string') {
          await change(event);
        } else if (typeof event === 'undefined' && props.type === 'tel') {
          await change('');
        } else {
          if (normalize) {
            await change(normalize(event.target.value, value) || '');
          } else {
            await onChange(event || '');
          }
        }

        props.onChange?.(event);
      }}
    />
  );
};
