import { createPortal } from 'react-dom';
import { useState, type JSX } from 'react';

import { Avatar } from 'components/avatar';
import { PictureCrop } from 'components/picture-crop';
import { CustomCssTransition } from 'components/custom-css-transition';

import * as Styles from './styles';
import { UploadPhotoProps } from './types';

const UploadPhoto = ({
  size,
  image,
  testId,
  setImage,
  className,
  placeholder,
}: UploadPhotoProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Avatar
        editMode
        url={image}
        size={size}
        data-testid={testId}
        className={className}
        placeholder={placeholder}
        onClick={() => setIsOpen(true)}
      />

      {createPortal(
        <CustomCssTransition
          in={isOpen}
          timeout={300}
          unmountOnExit
          classNames="upload-picture"
        >
          <Styles.Container className="upload-picture">
            <div className="upload-picture-overlay">
              <PictureCrop
                onClose={() => setIsOpen(false)}
                setImage={(img) => setImage(img)}
              />
            </div>
          </Styles.Container>
        </CustomCssTransition>,
        document.body
      )}
    </>
  );
};

export { UploadPhoto };
export type { UploadPhotoProps };
