import { useState, type JSX, PropsWithChildren } from 'react';

import { Flex } from 'components/flex';
import { Icon } from 'components/icon';
import { Input } from 'components/input';
import { Select } from 'components/select';
import { getTestId } from 'utils/test-utils';
import { Checkbox } from 'components/checkbox';
import { ErrorMessage } from 'components/error-message';
import { ResetButton } from 'components/specified-buttons';
import { CustomCssTransition } from 'components/custom-css-transition';
import { isSelectValue, getSelectValue, isFilterApplied } from 'utils/helpers';

import { RadioButton } from '../radio-button';

import { optionsLabelsAreString } from './helpers';
import { OptionWrap, SectionWrap, FilterSectionInner } from './styles';
import {
  SectionOption,
  FilterSectionType,
  FilterSectionProps,
  FilterSectionOnChangeValue,
} from './types';

const FilterSection = ({
  title,
  value,
  error,
  testId,
  options,
  onReset,
  onChange,
  children,
  inputProps,
  selectProps,
  resetDisabled,
  nativeIconColor,
  withSpaceBetween,
  openDefault = true,
  type = FilterSectionType.Checkbox,
}: PropsWithChildren<FilterSectionProps>): JSX.Element => {
  const [open, setOpen] = useState<boolean>(openDefault);

  const getLabel = (sectionLabel: SectionOption['label']) => {
    if (typeof sectionLabel === 'string') {
      return <p>{sectionLabel}</p>;
    }
    return sectionLabel;
  };

  const getFilterField = () => {
    if (onChange) {
      if (typeof value === 'string' && type !== FilterSectionType.Select) {
        if (type === FilterSectionType.Input) {
          return (
            <Input
              value={value}
              onChange={(e) =>
                onChange(typeof e === 'string' ? e : e.target.value)
              }
              {...inputProps}
            />
          );
        } else if (type === FilterSectionType.Radio && options) {
          return options.map(
            ({ id, icon, label, value: optionValue }, index) => {
              return (
                <OptionWrap
                  isRadio
                  key={index}
                  nativeIconColor={nativeIconColor}
                >
                  {icon}

                  {getLabel(label)}

                  <RadioButton
                    value={optionValue}
                    id={String(id ?? optionValue)}
                    checked={optionValue === value}
                    onChange={() => onChange(optionValue)}
                  />
                </OptionWrap>
              );
            }
          );
        }
      } else if (
        options &&
        value instanceof Set &&
        type === FilterSectionType.Checkbox
      ) {
        return options.map(({ icon, label, value: optionValue }, index) => {
          return (
            <OptionWrap
              key={index}
              nativeIconColor={nativeIconColor}
              withSpaceBetween={withSpaceBetween}
            >
              {icon}

              {getLabel(label)}

              <Checkbox
                value={optionValue}
                checked={value.has(optionValue)}
                onChange={() => onChange(optionValue)}
              />
            </OptionWrap>
          );
        });
      } else if (
        options &&
        optionsLabelsAreString(options) &&
        type === FilterSectionType.Select &&
        (Array.isArray(value) ||
          typeof value === 'string' ||
          isSelectValue(value))
      ) {
        return (
          <Select
            isSearchable
            options={options}
            isClearable={false}
            onChange={onChange}
            menuPortalTarget={document.body}
            value={getSelectValue({
              value,
              options,
              isCreatable: selectProps?.isCreatable,
              onlyValue: selectProps?.onlyValue ?? true,
            })}
            {...selectProps}
          />
        );
      }
    }

    return children;
  };

  return (
    <>
      <SectionWrap open={open} data-testid={getTestId(title) || testId}>
        <span>{title}</span>

        <Flex gap={4} alignItems="center">
          <ResetButton
            padding="0"
            onClick={onReset}
            disabled={resetDisabled ?? !isFilterApplied(value)}
          />
          <Icon.ChevronDown onClick={() => setOpen((o) => !o)} />
        </Flex>
      </SectionWrap>

      <CustomCssTransition
        in={open}
        mountOnEnter
        timeout={300}
        unmountOnExit
        appear={open}
        classNames="filter-section"
      >
        <FilterSectionInner>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {getFilterField()}
        </FilterSectionInner>
      </CustomCssTransition>
    </>
  );
};

export { FilterSection, FilterSectionType };
export type { FilterSectionOnChangeValue };
