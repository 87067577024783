import { useParams } from 'react-router-dom';

import { ButtonColors } from 'types';
import { useAppSelector } from 'store';
import { capitalize } from 'utils/string';
import { useDetailsQuery } from 'store/api/event';
import { selectAuthUser } from 'store/slices/auth';
import { useCreateMutation } from 'store/api/payout';
import { handleFormSubmitWithFiles } from 'utils/helpers';
import { useAlert, isMobile, handleMutation } from 'hooks';
import { Messages, acceptPdfAndImageFileTypes } from 'config';
import {
  Row,
  Col,
  Dialog,
  Button,
  DialogType,
  ButtonTypes,
  SelectField,
  PaymentsForm,
  CheckboxField,
  RadioFieldGroup,
  NewEntityButton,
  DropZoneContent,
  FileDropzoneField,
} from 'components';

import {
  getInitialValues,
  getValidationSchema,
  expenseTypeToPayoutMapping,
} from './config';
import {
  FormValues,
  ExpensesType,
  ExpenseTypeCashStatus,
  CreateBonusAdvanceDialogProps,
} from './types';

export const CreateBonusAdvanceDialog = ({
  eventId,
  isAdvances,
  withReceipt,
  customTitle,
  isOnlyCashLocation,
}: CreateBonusAdvanceDialogProps) => {
  const mobile = isMobile();
  const { id } = useParams();
  const [create, mutation] = useCreateMutation();
  const authUser = useAppSelector(selectAuthUser);
  const { handleError, showSuccessAlert } = useAlert();
  const { data, isFetching } = useDetailsQuery(id!, { skip: !id });

  const title = isAdvances ? 'advance' : 'bonus';
  handleMutation(mutation);

  const employeeOptions = [data?.leader, ...(data?.teamMembers || [])]
    ?.filter((value) => value && value?.id !== authUser?.id)
    .map((value) => ({
      label: String(value?.name),
      value: String(value?.employee?.id),
    }));

  const onSubmit = async (values: FormValues, close: () => void) => {
    const defaultType = isAdvances ? ExpensesType.Advance : ExpensesType.Bonus;

    const cashStatus = values.isCashFromEvent
      ? ExpenseTypeCashStatus.Prepaid
      : ExpenseTypeCashStatus.Regular;

    const type =
      expenseTypeToPayoutMapping[values.type || defaultType]?.[cashStatus];

    const response = await create({
      ...values,
      type,
      amount: +values.amount,
      employeeId: +values.employeeId,
    }).unwrap();

    if (response.id) {
      close();
      showSuccessAlert(`${type} ${Messages.ADDED_SUCCESSFULLY}`);
    }
  };

  return (
    <Dialog
      title={customTitle || `Create ${title}`}
      trigger={<NewEntityButton text={customTitle || `Add ${title}`} />}
      width={isOnlyCashLocation ? (mobile ? '90wv' : '600px') : undefined}
    >
      {(close, _, ButtonWrap) => (
        <PaymentsForm<FormValues>
          isDialog
          isTopChildren
          hideBackButton
          isDisableEvent
          initialValues={getInitialValues(eventId, isOnlyCashLocation)}
          validationSchema={getValidationSchema(
            isOnlyCashLocation,
            withReceipt
          )}
          footerButtons={({ dirty, isSubmitting }) => (
            <ButtonWrap type={DialogType.Plain}>
              <Button
                text="Cancel"
                onClick={close}
                fullWidth={mobile}
                colorType={ButtonColors.LightBlue}
              />
              <Button
                text="Add"
                fullWidth={mobile}
                type={ButtonTypes.Submit}
                disabled={!dirty || isSubmitting}
              />
            </ButtonWrap>
          )}
          onSubmit={
            withReceipt
              ? handleFormSubmitWithFiles({
                  data: {},
                  handleError,
                  onSubmit: (values) => onSubmit(values, close),
                  files: [
                    {
                      formFieldName: 'receipt',
                      dataFieldName: 'receiptUrl',
                      requestFieldName: 'receiptUrl',
                    },
                  ],
                })
              : (values) => onSubmit(values, close)
          }
        >
          {({ values }) => (
            <>
              {isOnlyCashLocation && (
                <Row>
                  <Col>
                    <RadioFieldGroup
                      name="type"
                      transparent
                      padding="0"
                      label="Select Type"
                      items={Object.values(ExpensesType).map((value) => ({
                        value,
                        label: capitalize(value),
                      }))}
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <SelectField
                    onlyValue
                    label="Employee"
                    name="employeeId"
                    isDisabled={isFetching}
                    options={employeeOptions}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <CheckboxField
                    name="isCashFromEvent"
                    label="Cash From Location"
                    disabled={isOnlyCashLocation}
                  />
                </Col>
              </Row>

              {withReceipt && (
                <Row>
                  <Col>
                    <FileDropzoneField
                      errorMessage
                      name="receipt"
                      label="Upload receipt"
                      accept={acceptPdfAndImageFileTypes}
                    >
                      <DropZoneContent file={values.receipt} />
                    </FileDropzoneField>
                  </Col>
                </Row>
              )}
            </>
          )}
        </PaymentsForm>
      )}
    </Dialog>
  );
};
