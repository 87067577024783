import { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import ReactSelect, { Props, GroupBase } from 'react-select';

import { isMobile } from 'hooks';
import { isMultiSelectValue } from 'utils/helpers';
import { ErrorMessage } from 'components/error-message';

import { useStyles } from './helpers';
import { Label, Wrapper, Required, SelectAllOption } from './styles';
import {
  Control,
  MultiValue,
  ClearIndicator,
  IndicatorsContainer,
} from './components';
import {
  SelectProps,
  SelectValue,
  SelectOption,
  MultiSelectValue,
  SelectOptionProps,
  SingleSelectValue,
} from './types';

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    IsMulti extends boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Group extends GroupBase<Option>,
  > {
    isOverflow: boolean;
    showAllValues?: boolean;
    maxVisibleValues?: number;
    onShowMoreClick: () => void;
  }
}

const selectAllOption = { value: 'selectAll', label: 'Select all' };
const unselectAllOption = { value: 'unselectAll', label: 'Unselect all' };

const Select = ({
  name,
  large,
  label,
  error,
  style,
  options,
  isMulti,
  onChange,
  className,
  isDisabled,
  components,
  isCreatable,
  isClearable,
  requiredText,
  defaultValue,
  isSearchable,
  defaultControl,
  errorOnlyBorder,
  maxVisibleValues,
  showSelectAll = true,
  placeholder = 'Select',
  placeholderColorAsText,
  ...props
}: SelectProps) => {
  const mobile = isMobile();
  const [showAllValues, setShowAllValues] = useState(false);

  useEffect(() => setShowAllValues(mobile), [mobile]);

  const styles = useStyles(large, error, placeholderColorAsText);

  const Component = isCreatable ? CreatableSelect : ReactSelect;
  const { value } = props;

  const getComponents = () => {
    const values: any = { Control, MultiValue, ClearIndicator };
    if (defaultControl) {
      delete values.Control;
    }
    return {
      ...values,
      IndicatorsContainer,
    };
  };

  const isMultiOptions = Array.isArray(options);
  const isMultiValue = Array.isArray(value);

  const handleChange: Props<SelectOption>['onChange'] = (selected, meta) => {
    if (onChange) {
      if (isMulti && isMultiSelectValue(selected) && Array.isArray(options)) {
        const selectType = selected.find(
          (option) =>
            option.value === selectAllOption.value ||
            option.value === unselectAllOption.value
        )?.value;

        onChange(
          selectType === selectAllOption.value
            ? options
            : selectType === unselectAllOption.value
              ? []
              : selected,
          meta
        );
      } else {
        onChange(selected, meta);
      }
    }
  };

  const getOptions = () => {
    if (isMulti && isMultiOptions && showSelectAll) {
      if (value?.length === options.length) {
        return [unselectAllOption, ...options];
      } else {
        return [selectAllOption, ...options];
      }
    }

    return options;
  };

  const isOverflow = Boolean(
    maxVisibleValues && value && isMultiValue && value.length > maxVisibleValues
  );

  return (
    <Wrapper
      style={style}
      className={className}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {label && (
        <Label disabled={isDisabled}>
          {label}&nbsp;
          <Required>{requiredText}</Required>
        </Label>
      )}

      <Component
        styles={styles}
        isMulti={isMulti}
        options={getOptions()}
        onChange={handleChange}
        isDisabled={isDisabled}
        inputId={name ?? label}
        isOverflow={isOverflow}
        placeholder={placeholder}
        isClearable={isClearable}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        showAllValues={showAllValues}
        maxVisibleValues={maxVisibleValues}
        onShowMoreClick={() => setShowAllValues(!showAllValues)}
        value={'value' in props && value === undefined ? null : value}
        components={{
          ...getComponents(),
          ...components,
        }}
        formatOptionLabel={(option) =>
          option.value === selectAllOption.value ||
          option.value === unselectAllOption.value ? (
            <SelectAllOption>{option.label}</SelectAllOption>
          ) : (
            option.label
          )
        }
        {...props}
      />

      {error && !errorOnlyBorder && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
};

export { Select };
export type {
  SelectProps,
  SelectValue,
  SelectOption,
  MultiSelectValue,
  SingleSelectValue,
  SelectOptionProps,
};
