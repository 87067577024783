import { useParams } from 'react-router-dom';

import { Messages } from 'config';
import { useAlert, handleMutation } from 'hooks';
import { NewEntityButtonWithRef } from 'components';
import { useCreateMutation } from 'store/api/event-expenses';

import { Form } from './form';

export const CreateExpense = () => {
  const { id } = useParams();
  const { showSuccessAlert } = useAlert();
  const [create, mutation] = useCreateMutation();

  handleMutation({
    ...mutation,
    onSuccess: () => {
      showSuccessAlert(`Expense ${Messages.CREATED_SUCCESSFULLY}`);
    },
  });

  return (
    <Form
      title="Create Expense"
      trigger={<NewEntityButtonWithRef text="New Expense" />}
      onSubmit={(values) =>
        create({
          ...values,
          eventId: Number(id!),
          amount: +values.amount,
          receiptUrl: values.receiptUrl,
          categoryId: +values.categoryId!,
          paymentMethodId: +values.paymentMethodId!,
        })
      }
    />
  );
};
