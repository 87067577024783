import dayjs from 'dayjs';
import type { JSX } from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

import { Icon } from 'components/icon';
import { ButtonColors } from 'types/enums';
import { Button } from 'components/button';

import * as Styles from './styles';

export const Header = ({
  monthDate,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps): JSX.Element => {
  return (
    <Styles.Header>
      <Button
        padding="0"
        onClick={decreaseMonth}
        icon={<Icon.ChevronLeft />}
        disabled={prevMonthButtonDisabled}
        colorType={ButtonColors.Transparent}
      />

      <p>{dayjs(monthDate).format('MMMM YYYY')}</p>
      <Button
        padding="0"
        onClick={increaseMonth}
        icon={<Icon.ChevronRight />}
        disabled={nextMonthButtonDisabled}
        colorType={ButtonColors.Transparent}
      />
    </Styles.Header>
  );
};
