import type { JSX, Ref } from 'react';

import { withRef } from 'hoc';
import { AvatarColor } from 'types';
import { Icon } from 'components/icon';
import { Image } from 'components/image';
import { getRandomColor, getUserInitials } from 'utils/helpers';

import * as Styles from './styles';
import { AvatarProps, AvatarPlaceholderType } from './types';

const getPlaceholder = (
  userName?: string,
  color?: AvatarColor,
  type?: AvatarPlaceholderType,
  border?: boolean,
  onClick?: () => void,
  icon?: JSX.Element,
  square?: boolean
) => {
  switch (type) {
    case AvatarPlaceholderType.Hospital:
      return (
        <Styles.GreyPlaceholder square={square}>
          {icon || <Icon.Hospital />}
        </Styles.GreyPlaceholder>
      );
    case AvatarPlaceholderType.Stethoscope:
      return (
        <Styles.GreyPlaceholder square={square}>
          <Icon.Stethoscope />
        </Styles.GreyPlaceholder>
      );
    case AvatarPlaceholderType.Logo:
      return (
        <Styles.LogoPlaceholder isDark onClick={onClick}>
          Logo
        </Styles.LogoPlaceholder>
      );
    case AvatarPlaceholderType.Icon:
      return (
        <Styles.LogoPlaceholder onClick={onClick}>
          {icon}
        </Styles.LogoPlaceholder>
      );
    case AvatarPlaceholderType.GreyIcon:
      return (
        <Styles.GreyPlaceholder square={square} onClick={onClick}>
          {icon}
        </Styles.GreyPlaceholder>
      );
    case AvatarPlaceholderType.UserAvatar:
      return (
        <Styles.ColorAvatarPlaceholder
          square={square}
          onClick={onClick}
          color={color ? color : AvatarColor.Blue}
        >
          <p>{userName}</p>
        </Styles.ColorAvatarPlaceholder>
      );
    default:
      return (
        <Styles.GreyPlaceholder square={square}>
          <Icon.Camera />
        </Styles.GreyPlaceholder>
      );
  }
};

const Avatar: React.FC<AvatarProps> = ({
  url,
  icon,
  color,
  border,
  square,
  onClick,
  editMode,
  userName,
  size = 40,
  refForward,
  placeholder,
  borderWidth,
  highlight = false,
  ...props
}) => {
  const showBorder =
    border ||
    !placeholder ||
    placeholder === AvatarPlaceholderType.Default ||
    highlight;

  const randomColor = () => {
    if (!color) {
      if (placeholder === AvatarPlaceholderType.UserAvatar && userName) {
        return getRandomColor(userName, AvatarColor);
      }
    } else {
      return color;
    }
  };

  return (
    <Styles.AvatarBlock
      size={size}
      onClick={onClick}
      hasBorder={showBorder}
      $highlight={highlight}
      $borderWidth={borderWidth}
      ref={refForward as Ref<HTMLDivElement>}
      {...props}
    >
      {url ? (
        <>
          <Image
            src={url}
            alt="avatar"
            objectFit="cover"
            clickable={!!onClick}
            style={{
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              borderRadius: square ? '16px' : '50%',
            }}
          />
          {editMode && (
            <Styles.ImageMask onClick={onClick}>
              <Icon.Camera />
            </Styles.ImageMask>
          )}
        </>
      ) : (
        getPlaceholder(
          userName && getUserInitials(userName).slice(0, 2),
          randomColor(),
          placeholder,
          !showBorder,
          onClick,
          icon,
          square
        )
      )}
    </Styles.AvatarBlock>
  );
};

export const AvatarWithRef = withRef(Avatar);

export { Avatar, AvatarPlaceholderType };
export type { AvatarProps };
