import { PropsValue } from 'react-select';
import { type JSX, ReactNode } from 'react';

import { InputProps } from '../input';
import { IdObject } from '../../types';
import { SelectFieldProps } from '../fields/select';
import { SelectProps, SelectOption } from '../select';

export enum FilterSectionType {
  Input,
  Select,
  Checkbox,
  Radio,
}

export interface FilterSectionProps {
  title: string;
  error?: string;
  testId?: string;
  onReset?: () => void;
  openDefault?: boolean;
  inputProps?: InputProps;
  resetDisabled?: boolean;
  type?: FilterSectionType;
  nativeIconColor?: boolean;
  options?: SectionOption[];
  withSpaceBetween?: boolean;
  value?: string | string[] | Set<string>;
  onChange?: (v: FilterSectionOnChangeValue) => void;
  selectProps?: SelectProps & Pick<SelectFieldProps, 'onlyValue'>;
}

export interface SectionOption extends Partial<IdObject> {
  value: string;
  icon?: JSX.Element;
  label: string | ReactNode;
}

export type FilterSectionOnChangeValue = string | PropsValue<SelectOption>;

export interface SectionWrapProps {
  open: boolean;
}
