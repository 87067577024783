import { useField, FieldArray } from 'formik';
import { type JSX, cloneElement, isValidElement } from 'react';

import { Flex } from 'components/flex';
import { Icon } from 'components/icon';
import { Link } from 'components/link';
import { controlError } from 'utils/error';
import { ErrorMessage } from 'components/error-message';
import { GroupLabel } from 'components/radio-button/styles';
import { Checkbox, CheckboxProps } from 'components/checkbox';

import * as Styles from './styles';
import { CheckboxFieldGroupProps } from './types';

const CheckboxFieldGroup = ({
  name,
  grid,
  items,
  label,
  subtitle,
  gap = 16,
  className,
  labelStyle,
  commonProps,
  isInlineGrid,
  checkAllValue,
  selectedAllLabel,
  checkAllSeparateLine,
  checkAllLabel = 'All',
  controlErrorLabel = true,
}: CheckboxFieldGroupProps): JSX.Element => {
  const [field, meta, helpers] = useField(name);
  const { value: fieldArray } = field;

  const error = controlError(meta, name, label, controlErrorLabel);

  const isCheckboxProps = (
    item: JSX.Element | CheckboxProps
  ): item is CheckboxProps => {
    const propsObj = item as CheckboxProps;

    return propsObj.value !== undefined;
  };

  const isSelectedAll = fieldArray?.length === checkAllValue?.length;

  const checkAllCheckbox = !!checkAllValue?.length && (
    <Checkbox
      checkedIcon={<Icon.Minus />}
      checked={Boolean(isSelectedAll)}
      id={`checkbox-group-${name}-all`}
      key={`checkbox-group-${name}-all`}
      label={isSelectedAll ? selectedAllLabel : checkAllLabel}
      onChange={(e) => {
        helpers.setValue(e.target.checked ? checkAllValue : []);
        helpers.setTouched(true);
      }}
      {...commonProps}
    />
  );

  return (
    <div role="group" aria-labelledby="checkbox-group">
      {label && (
        <GroupLabel column style={labelStyle}>
          {label}
          {subtitle && <Styles.Subtitle>{subtitle}</Styles.Subtitle>}
        </GroupLabel>
      )}
      {checkAllSeparateLine && (
        <Styles.CheckboxAllContainer>
          {checkAllCheckbox}
        </Styles.CheckboxAllContainer>
      )}
      <FieldArray
        name={name}
        render={({ push, remove }) => (
          <Styles.GroupContainer
            gap={gap}
            grid={grid}
            className={className}
            isInlineGrid={isInlineGrid}
          >
            {!checkAllSeparateLine && checkAllCheckbox}

            {items.map((item, index) => {
              if (isValidElement(item)) {
                return cloneElement(item, {
                  key: index.toString(),
                  ...commonProps,
                });
              } else if (isCheckboxProps(item)) {
                const { value } = item;
                const id = item.id || `${name}-${value}-${index}`;

                return (
                  <Flex
                    gap={8}
                    key={index.toString()}
                    flexWrap={item?.linkProps ? 'wrap' : 'nowrap'}
                  >
                    <Checkbox
                      id={id}
                      name={name}
                      checked={Boolean(fieldArray?.includes(value))}
                      onChange={(e) => {
                        if (e.target.checked) {
                          push(value);
                        } else {
                          remove(fieldArray.indexOf(value));
                        }
                        helpers.setTouched(true);
                      }}
                      {...commonProps}
                      {...item}
                    />
                    {item?.linkProps && <Link {...item.linkProps} />}
                  </Flex>
                );
              }
            })}
          </Styles.GroupContainer>
        )}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};

export { CheckboxFieldGroup };
export type { CheckboxFieldGroupProps };
