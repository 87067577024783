import { createPortal } from 'react-dom';
import { type JSX, PropsWithChildren } from 'react';

import { Button } from 'components/button';
import { ButtonColors } from 'types/enums';
import { EllipsisText } from 'components/ellipsis-text';
import { CustomCssTransition } from 'components/custom-css-transition';

import * as Styles from './styles';
import { SlidePanelProps } from './types';

export const SlidePanel = ({
  title,
  testId,
  onClose,
  actions,
  children,
  onExited,
  hideOverlay,
  width = 320,
  topRightSlot,
  leftDirection,
  isOpen = false,
  rightOffset = 0,
  bottomComponent,
  unmountOnExit = true,
  closeTitle = 'Cancel',
  contentPadding = '0 16px',
}: PropsWithChildren<SlidePanelProps>): JSX.Element => {
  return createPortal(
    <CustomCssTransition
      in={isOpen}
      timeout={300}
      onExited={onExited}
      classNames="slide-panel"
      unmountOnExit={unmountOnExit}
    >
      <Styles.Container
        className="slide-panel"
        rightOffset={rightOffset}
        leftDirection={leftDirection}
        data-testid={testId ?? 'slide-panel'}
        width={typeof width === 'number' ? `${width}px` : width}
      >
        {!hideOverlay && <div className="slide-panel-overlay" />}
        <div className="slide-panel-content">
          <Styles.Header>
            {typeof title === 'string' ? (
              <EllipsisText>
                <Styles.Title>{title}</Styles.Title>
              </EllipsisText>
            ) : (
              <Styles.TitleWrap>{title}</Styles.TitleWrap>
            )}
            {topRightSlot && topRightSlot}
          </Styles.Header>
          <Styles.Content padding={contentPadding}>{children}</Styles.Content>
          {bottomComponent ? (
            bottomComponent
          ) : (
            <Styles.Footer>
              <Button
                fullWidth
                text={closeTitle}
                onClick={onClose}
                colorType={ButtonColors.LightBlue}
              />
              {actions}
            </Styles.Footer>
          )}
        </div>
      </Styles.Container>
    </CustomCssTransition>,
    document.body
  );
};
