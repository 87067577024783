import type { JSX } from 'react';

import { isMobile } from 'hooks';

import * as Styles from './styles';
import { FormFooterProps } from './types';

const FormFooter = ({
  left,
  right,
  asMobile,
  ...props
}: FormFooterProps): JSX.Element => {
  const mobile = isMobile();
  const value = asMobile ? asMobile : mobile;
  return (
    <Styles.Footer mobile={value} hasGap={!!left && !!right} {...props}>
      <Styles.Children mobile={value}>{left}</Styles.Children>
      <Styles.Children mobile={value}>{right}</Styles.Children>
    </Styles.Footer>
  );
};

export { FormFooter };
