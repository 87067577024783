import { useField } from 'formik';
import { PropsWithChildren } from 'react';

import { CommonFieldProps } from 'types';
import { controlError } from 'utils/error';
import { ErrorMessage } from 'components/error-message';
import { Label } from 'components/input-container/styles';
import { DropZone, DropZoneProps } from 'components/drop-zone';

interface FileFieldProps
  extends Omit<DropZoneProps, 'setValue'>,
    CommonFieldProps {
  className?: string;
}

const FileDropzoneField = ({
  name,
  label,
  children,
  className,
  controlErrorLabel = true,
  ...props
}: PropsWithChildren<FileFieldProps>) => {
  const [, meta, helpers] = useField({ name, type: 'file' });

  const error = controlError(meta, name, label, controlErrorLabel);

  return (
    <div className={className}>
      <DropZone
        setValue={async (file) => {
          await helpers.setTouched(true);
          helpers.setValue(file);
        }}
        {...props}
      >
        <>
          {label && <Label>{label}</Label>}
          {children}
        </>
      </DropZone>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};

export { FileDropzoneField };
