import type { JSX } from 'react';

import { Row } from 'components/grid';
import { Flex } from 'components/flex';
import { ListCard } from 'components/list-card';
import { ListHeader } from 'components/list-header';
import { textValue, formatNumber } from 'utils/helpers';

import { GraphListProps } from '../types';

import * as Styles from './styles';
import { GraphListCells, GraphListTemplates } from './config';

export const GraphList = ({
  data,
  colors,
  titles,
  innerRef,
  mobileTitles,
}: GraphListProps): JSX.Element => {
  return (
    <Styles.Container ref={innerRef}>
      {!data.length ? (
        <Row>
          <Styles.Col col={8}>
            <h3>Your apps haven't received any data yet</h3>
          </Styles.Col>

          <Styles.Col col={8}>
            <p>
              We will start showing your performance statistic right after
              you'll add your first data.
            </p>
          </Styles.Col>
        </Row>
      ) : (
        <>
          <ListHeader
            padding="4px"
            titles={titles}
            template={GraphListTemplates.Desktop}
          />

          <div>
            {data.map((item, idx) => {
              const name = (
                <Flex gap={14} alignItems="center">
                  <Styles.Dot bg={colors[idx % colors.length]} />
                  <Styles.Wrapper>
                    <Styles.Title>{item.name ?? item.id}</Styles.Title>
                    {item?.subtitle && (
                      <Styles.Subtitle>{item.subtitle}</Styles.Subtitle>
                    )}
                  </Styles.Wrapper>
                </Flex>
              );

              const label = item.label;
              const percent = textValue(
                formatNumber(item.value, '0,0.00'),
                '_value_%'
              );

              const mobileValues = mobileTitles.map((title) => (
                <Styles.MobileTitle key={title}>{title}</Styles.MobileTitle>
              ));

              return (
                <ListCard
                  rowGap={8}
                  padding="16px 22px"
                  key={`${item.id}-${idx}`}
                  cellsMobile={GraphListCells.Mobile}
                  template={GraphListTemplates.Desktop}
                  mobileTemplate={GraphListTemplates.Mobile}
                  tabletTemplate={GraphListTemplates.Tablet}
                  values={[name, label, percent, ...mobileValues]}
                />
              );
            })}
          </div>
        </>
      )}
    </Styles.Container>
  );
};
