import { IdObject } from 'types';
import { Event } from 'store/api/event';
import { Carrier } from 'store/api/carriers';
import { Product } from 'store/api/product/types';
import { Supplier } from 'store/api/supplier/types';

export enum StockOrderStatus {
  Pending = 'Pending',
  Shipped = 'Shipped',
  InTransit = 'InTransit',
  Exception = 'Exception',
  Shipment = 'Shipment',
  Done = 'Done',
}

export const StockOrderStatusLabel = {
  [StockOrderStatus.Done]: 'Done',
  [StockOrderStatus.Pending]: 'Pending',
  [StockOrderStatus.Shipped]: 'Shipped',
  [StockOrderStatus.Shipment]: 'Shipment',
  [StockOrderStatus.Exception]: 'Exception',
  [StockOrderStatus.InTransit]: 'In Transit',
};

export interface EditStockOrderFormValues
  extends Partial<
      Omit<
        StockOrder,
        | 'id'
        | 'date'
        | 'items'
        | 'carrier'
        | 'dateShipped'
        | 'estimatedDeliveryDate'
      >
    >,
    Pick<StockOrder, 'id'> {
  date?: Date;
  eventId?: number;
  carrierId?: number;
  supplierId?: number;
  dateShipped?: Date | null;
  eventIds?: number[] | null;
  estimatedDeliveryDate?: Date;
  recipient?: 'event' | 'storage';
  orderTo?: 'storage' | 'terminal';
  items?: {
    count: string;
    price: number;
    productId: number;
    totalPrice: number;
  }[];
}

export interface StockOrder extends IdObject {
  date: string;
  amount: string;
  number: string;
  events: Event[];
  carrier?: Carrier;
  createdBy: number;
  ltlPrice?: string;
  storage?: Storage;
  supplier: Supplier;
  event?: Event | null;
  dateShipped?: string;
  phone?: string | null;
  trackingNumber: string;
  linkToWebsite?: string;
  address?: string | null;
  storageId: number | null;
  status: StockOrderStatus;
  items: StockOrderProduct[];
  contactName?: string | null;
  estimatedDeliveryDate: string;
  emergencyPhone?: string | null;
}

export interface StockOrderProduct extends IdObject {
  count: number;
  cases: number;
  price: number;
  units: number;
  product: Product;
  productId: number;
}

export interface StockOrderCompleteRequest extends IdObject {
  eventId?: number;
}

export type CreateStockOrderFormValues = Omit<EditStockOrderFormValues, 'id'>;
