import { CSSTransition } from 'react-transition-group';
import type { CSSTransitionProps } from 'react-transition-group/CSSTransition';
import { useRef, cloneElement, isValidElement, PropsWithChildren } from 'react';

type CustomCssTransitionProps = PropsWithChildren & CSSTransitionProps;

export const CustomCssTransition = ({
  children,
  ...props
}: CustomCssTransitionProps) => {
  const nodeRef = useRef(null);

  const childWithRef = isValidElement(children)
    ? // @ts-ignore
      cloneElement(children, { ref: nodeRef })
    : children;

  return (
    <CSSTransition {...props} nodeRef={nodeRef}>
      {childWithRef}
    </CSSTransition>
  );
};
