import 'quill-paste-smart';
import AutoLinks from 'quill-auto-links';
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';
import { memo, useRef, useMemo, useState, useEffect } from 'react';

import { withRef } from 'hoc';
import { urlPattern } from 'utils/regex';

import * as Styles from './styles';
import { RichInputProps } from './types';

Quill.register('modules/autoLinks', AutoLinks);

const modules = {
  autoLinks: true,
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['link', 'clean'],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ],
  keyboard: {
    bindings: {
      enter: {
        key: 13,
        handler: () => {
          // just disable enter, handled by onKeyDown in ChatInput
        },
      },
    },
  },
  clipboard: {
    magicPasteLinks: true,
    substituteBlockElements: true,
    allowed: {
      attributes: ['href', 'rel', 'target'],
      tags: [
        'a',
        'b',
        'u',
        's',
        'i',
        'p',
        'br',
        'ul',
        'ol',
        'li',
        'span',
        'strong',
      ],
    },
  },
};

export const RichInput = ({
  value,
  onBlur,
  maxRows,
  onFocus,
  onChange,
  className,
  withBorder,
  refForward,
  placeholder,
  transparent,
  ...props
}: RichInputProps) => {
  const quillRef = useRef<ReactQuill | null>(undefined);
  const quillTooltipHandled = useRef(false);
  const [isFocused, setFocused] = useState(false);

  const defaultModules = useMemo(
    () => ({
      ...modules,
      mention: {
        isolateCharacter: true,
        defaultMenuOrientation: 'top',
        mentionDenotationChars: ['@'],
        allowedChars: /^[A-Za-z\s]*$/,
      },
    }),
    []
  );

  useEffect(() => {
    if (quillRef.current) {
      quillRef.current.getEditor().root.dataset.placeholder = placeholder;
    }
  }, [placeholder]);

  const isEditorHidden = document
    .getElementsByClassName('ql-tooltip')[0]
    ?.classList.contains('ql-hidden');

  const inputError = document.getElementsByClassName('ql-error')[0];

  useEffect(() => {
    if (isEditorHidden && inputError) {
      inputError.classList.remove('ql-error');
    }
  }, [isEditorHidden, inputError]);

  useEffect(() => {
    if (!inputError) {
      return;
    }
    const onRemove = () => {
      inputError.classList.remove('ql-error');
    };
    inputError.addEventListener('input', onRemove);
    return () => inputError.removeEventListener('input', onRemove);
  }, [inputError]);

  useEffect(() => {
    const editor = quillRef.current?.getEditor();
    if (editor && !quillTooltipHandled.current) {
      const tooltip = (editor as any).theme.tooltip;

      const tooltipSave = tooltip.save;

      tooltip.save = function () {
        const url = this.textbox.value;
        this.textbox.classList.remove('ql-error');
        if (url.match(urlPattern)) {
          tooltipSave.call(this);
        } else {
          this.textbox.classList.add('ql-error');
        }

        quillTooltipHandled.current = true;
      };
    }
  }, [quillRef.current]);

  return (
    <Styles.Rich
      $maxRows={maxRows}
      focused={isFocused}
      className={className}
      $withBorder={withBorder}
      $transparent={transparent}
    >
      <ReactQuill
        theme="snow"
        modules={{ ...defaultModules, ...props.modules }}
        {...props}
        value={value}
        onChange={(...args) => {
          onChange?.(...args);
        }}
        onFocus={() => {
          setFocused(true);
          onFocus?.();
        }}
        onBlur={(e) => {
          setFocused(false);
          onBlur?.(e);
        }}
        ref={(ref) => {
          quillRef.current = ref;
          refForward?.(ref);
        }}
      />
    </Styles.Rich>
  );
};

export const RichInputWithRef = memo(withRef(RichInput));
