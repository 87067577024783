import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import { useState, type JSX, useCallback } from 'react';

import { isMobile } from 'hooks';
import { Flex } from 'components/flex';
import { Icon } from 'components/icon';
import { DropZone } from 'components/drop-zone';
import { DropImage } from 'components/drop-image';
import { Button, ButtonTypes } from 'components/button';
import { UploadFile, ButtonColors, ButtonStyleTypes } from 'types';

import * as Styles from './styles';
import getCroppedImg from './cropImage';
import { PictureCropProps } from './types';

const PictureCrop = ({ onClose, setImage }: PictureCropProps): JSX.Element => {
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [imageToCrop, setImageToCrop] = useState<UploadFile | null>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const loadCroppedImage = useCallback(async () => {
    try {
      if (!imageToCrop) {
        throw new Error();
      }

      const croppedImage = await getCroppedImg(
        imageToCrop.src,
        croppedAreaPixels,
        rotation
      );

      if (typeof croppedImage?.src === 'string') {
        const file = new File([croppedImage.blob], imageToCrop.file.name, {
          type: croppedImage.blob.type,
        });

        setImage({
          file,
          src: croppedImage.src,
        });
        onClose();
      }
    } catch (e) {
      setIsError(true);
      setRotation(0);
      setImage(null);
      setImageToCrop(null);
    }
  }, [croppedAreaPixels, rotation, imageToCrop]);

  console.log('imageToCrop', imageToCrop);

  return (
    <Styles.Container data-testid="picture-crop">
      <h3>Add photo</h3>

      <Styles.CropWrapper bottomMargin={!imageToCrop}>
        {imageToCrop ? (
          <Cropper
            aspect={1}
            crop={crop}
            zoom={zoom}
            showGrid={false}
            cropShape="round"
            rotation={rotation}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            image={imageToCrop.src}
            objectFit="vertical-cover"
            onCropComplete={(_, croppedPixels: Area) =>
              setCroppedAreaPixels(croppedPixels)
            }
            classes={{
              cropAreaClassName: 'crop-area',
              containerClassName: 'crop-container',
            }}
          />
        ) : (
          <DropZone
            errorMessage
            isError={isError}
            isLoading={isLoading}
            setIsError={setIsError}
            children={<DropImage />}
            setIsLoading={setIsLoading}
            setValue={(value) => setImageToCrop(value as UploadFile)}
            accept={{
              'image/png': [],
              'image/jpg': [],
              'image/jpeg': [],
            }}
          />
        )}
      </Styles.CropWrapper>

      {imageToCrop && (
        <Styles.RotateWrap>
          <Button
            transparent
            text="Rotate"
            icon={<Icon.Rotate />}
            type={ButtonTypes.Button}
            colorType={ButtonColors.Blue}
            styleType={ButtonStyleTypes.Ghost}
            onClick={() => setRotation((prev) => prev + 90)}
          />
        </Styles.RotateWrap>
      )}

      <Flex
        gap={24}
        flexDirection={isMobile() ? 'column' : 'row'}
        justifyContent={imageToCrop ? 'space-between' : 'flex-end'}
      >
        {imageToCrop && (
          <Button
            text="Delete"
            type={ButtonTypes.Button}
            colorType={ButtonColors.LightRed}
            styleType={ButtonStyleTypes.Standard}
            onClick={() => {
              setImageToCrop(null);
              setZoom(1);
              setRotation(0);
            }}
          />
        )}

        <Flex gap={24} justifyContent="space-between">
          <Button
            text="Cancel"
            onClick={onClose}
            type={ButtonTypes.Button}
            colorType={ButtonColors.LightBlue}
            styleType={ButtonStyleTypes.Standard}
          />

          <Button
            text="Save"
            disabled={!imageToCrop}
            type={ButtonTypes.Button}
            onClick={loadCroppedImage}
            colorType={ButtonColors.Blue}
            styleType={ButtonStyleTypes.Standard}
          />
        </Flex>
      </Flex>
    </Styles.Container>
  );
};

export { PictureCrop };
