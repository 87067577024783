import { useState } from 'react';

import { Roles } from 'types';
import { isMobile } from 'hooks';
import { useAppSelector } from 'store';
import { formatDate } from 'utils/date';
import { joinStrings } from 'utils/helpers';
import { useReportQuery } from 'store/api/event';
import { selectAuthUserRole } from 'store/slices/auth';
import { Tabs, Flex, Drawer, Loader, FlexProps } from 'components';

import { EventReportProps } from '../types';
import { EventHeader } from '../event-header';

import * as Styles from './styles';
import { Revenue } from './revenue';
import { Bonuses } from './bonuses';
import { Expenses } from './expenses';
import { CashFlow } from './cash-flow';
// import { Received } from './received';
import { Inventory } from './inventory';
import { Tab, TabTitles } from './types';
import { Commissions } from './commissions';
import { StockOrders } from './stock-orders';
import { Reimbursement } from './reimbursement';
import { ProfitAndLosses } from './profit-and-losses';

const commonFlexProps: FlexProps = {
  gap: 24,
  minHeight: 0,
  fullHeight: true,
  flexDirection: 'column',
};

const hiddenTabs = [Tab.ProfitAndLosses, Tab.StockOrders, Tab.Commissions];
const enableProfitAndLossesRoles = [Roles.COMPANY_OWNER, Roles.SITE_ADMIN];

export const EventReport = ({ event, opened, onClose }: EventReportProps) => {
  const mobile = isMobile();
  const [tab, setTab] = useState<Tab>(Tab.Revenue);
  const authUserRole = useAppSelector(selectAuthUserRole);

  const { data, isLoading } = useReportQuery({
    id: String(event.id),
  });

  const tabs = Object.values(Tab)
    .filter((value) =>
      authUserRole && hiddenTabs.includes(value)
        ? enableProfitAndLossesRoles.includes(authUserRole as Roles)
        : true
    )
    .map((value) => ({
      to: value,
      label: TabTitles[value],
    }));

  const commonProps = { event, data: data || [] };

  const tabComponents = {
    [Tab.Bonuses]: <Bonuses event={event} />,
    [Tab.Revenue]: <Revenue {...commonProps} />,
    [Tab.Expenses]: <Expenses {...commonProps} />,
    [Tab.CashFlow]: <CashFlow {...commonProps} />,
    // TODO: uncomment when it was ready
    // [Tab.Received]: <Received {...commonProps} />,
    [Tab.Inventory]: <Inventory {...commonProps} />,
    [Tab.Commissions]: <Commissions event={event} />,
    [Tab.StockOrders]: <StockOrders event={event} />,
    [Tab.Reimbursements]: <Reimbursement event={event} />,
    [Tab.ProfitAndLosses]: <ProfitAndLosses {...commonProps} />,
  };

  return (
    <Drawer opened={opened} topOffset={mobile ? '10%' : '20%'}>
      <Loader isFullScreen={false} isLoading={isLoading}>
        {data && (
          <Flex {...commonFlexProps}>
            <EventHeader
              event={event}
              onClose={onClose}
              title="Event Summary Report"
              isEmptyReport={!data?.length}
              date={joinStrings(
                [formatDate(event.startDate), formatDate(event.endDate)],
                ' - '
              )}
            />

            <Flex {...commonFlexProps}>
              <Tabs
                value={tab}
                items={tabs}
                onChange={(value) => setTab(value as Tab)}
              />
              <Styles.TabContainer>{tabComponents[tab]}</Styles.TabContainer>
            </Flex>
          </Flex>
        )}
      </Loader>
    </Drawer>
  );
};
