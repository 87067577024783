import { type JSX, PropsWithChildren } from 'react';

import { isMobile, isTablet, isDesktop, isTabletUp } from 'hooks/styles';

export const Desktop = ({
  children,
}: PropsWithChildren): JSX.Element | null => {
  return isDesktop() ? <>{children}</> : null;
};

export const NotDesktop = ({
  children,
}: PropsWithChildren): JSX.Element | null => {
  return !isDesktop() ? <>{children}</> : null;
};

export const TabletUp = ({
  children,
}: PropsWithChildren): JSX.Element | null => {
  return isTabletUp() ? <>{children}</> : null;
};

export const Tablet = ({ children }: PropsWithChildren): JSX.Element | null => {
  return isTablet() ? <>{children}</> : null;
};

export const Mobile = ({ children }: PropsWithChildren): JSX.Element | null => {
  return isMobile() ? <>{children}</> : null;
};
