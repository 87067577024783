import type { JSX } from 'react';
import { ResponsivePie } from '@nivo/pie';

import { defaultColors } from 'utils/charts';

import * as Styles from './styles';
import { PieChartProps, PieChartDataProps } from './types';
import { getTooltip, Placeholder, getCenteredMetric } from './config';

const PieChart = ({
  data,
  total,
  title,
  tooltip,
  placeholder,
  valueDescription,
  labelDescription,
  colors = defaultColors,
  containerWidth = '272px',
  containerHeight = '272px',
  margin = { top: 12, left: 12, right: 12, bottom: 12 },
  ...props
}: PieChartProps): JSX.Element => {
  return (
    <Styles.Container
      containerWidth={containerWidth}
      containerHeight={containerHeight}
    >
      {!data.length ? (
        placeholder || <Placeholder title={title} />
      ) : (
        <ResponsivePie
          data={data}
          colors={colors}
          margin={margin}
          innerRadius={0.7}
          borderColor="inherit"
          enableArcLabels={false}
          enableArcLinkLabels={false}
          activeOuterRadiusOffset={10}
          arcLinkLabelsColor={{ from: 'color' }}
          tooltip={
            tooltip ||
            getTooltip(colors, data, labelDescription, valueDescription)
          }
          layers={[
            'arcs',
            'arcLabels',
            'arcLinkLabels',
            'legends',
            getCenteredMetric(title, total),
          ]}
          {...props}
        />
      )}
    </Styles.Container>
  );
};

export { PieChart };
export type { PieChartDataProps };
