import { useMemo, useState, useEffect } from 'react';

import { UseQueryReturnProps } from 'types';

interface QueryProps {
  key: string;
  query: UseQueryReturnProps;
}

/**
 * useFilteredQueries
 * A custom hook that filters an array of queries based on their loading state and specified keys to exclude.
 *
 * @param {QueryProps[]} queries - An array of objects each with a key and a query.
 * @param {string[]} excludeOnFirstLoad - An array of keys that should be excluded after the first full load.
 *
 * @returns {UseQueryReturnProps[]} - An array of queries that are not excluded.
 *
 * @example
 * const queries = [
 *   { key: 'sales', query: salesProps },
 *   { key: 'payout', query: payoutProps },
 *   { key: 'employee', query: employeeProps },
 * ];
 * const excludeKeys = ['payout'];
 *
 * const filteredQueries = useFilteredQueries(queries, excludeKeys);
 * // Returns: [salesProps, employeeProps]
 */
export const useFilteredQueries = (
  queries: QueryProps[],
  excludeOnFirstLoad: string[]
) => {
  const [firstLoadDone, setFirstLoadDone] = useState(false);

  useEffect(() => {
    const allLoaded = queries.every((query) => !query.query.isFetching);
    if (allLoaded) {
      setFirstLoadDone(true);
    }
  }, [queries]);

  const filteredQueries = useMemo(() => {
    if (!firstLoadDone) {
      return queries;
    }

    return queries.filter((query) => !excludeOnFirstLoad.includes(query.key));
  }, [firstLoadDone, queries, excludeOnFirstLoad]);

  return filteredQueries.map((query) => query.query);
};
