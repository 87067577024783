import { Row, Col } from 'components/grid';
import { InputField, SelectField, DatePickerField } from 'components/fields';

import { PaymentsFormFieldsProps } from './types';

export const PaymentsFormFields = ({
  isDialog,
  isFetching,
  eventOptions,
  isDisableEvent,
}: PaymentsFormFieldsProps) => (
  <>
    <Row>
      <Col col={isDialog ? undefined : 4}>
        <SelectField
          onlyValue
          label="Event"
          name="eventId"
          options={eventOptions}
          placeholder="Select event"
          isDisabled={isFetching || isDisableEvent}
        />
      </Col>
    </Row>
    <Row>
      <Col colTablet={6} col={isDialog ? undefined : 2}>
        <InputField
          number
          decimal
          name="amount"
          label="Expense"
          placeholder="Add expense"
        />
      </Col>
      <Col colTablet={6} col={isDialog ? undefined : 2}>
        <DatePickerField
          name="date"
          label="Date"
          placeholderText="Select date"
        />
      </Col>
    </Row>
    <Row>
      <Col col={isDialog ? undefined : 4}>
        <InputField
          name="notes"
          type="textarea"
          maxLength={200}
          label="Description"
          placeholder="Add description"
        />
      </Col>
    </Row>
  </>
);
