import { PayoutType } from 'types';

export const payoutTitles: Record<PayoutType, string> = {
  [PayoutType.Fee]: 'Fee',
  [PayoutType.Bonus]: 'Bonus',
  [PayoutType.Refund]: 'Refund',
  [PayoutType.Advance]: 'Advance',
  [PayoutType.Dispute]: 'Dispute',
  [PayoutType.Penalty]: 'Penalty',
  [PayoutType.Earnings]: 'Commissions',
  [PayoutType.Adjustment]: 'Adjustment',
  [PayoutType.Expense]: 'Reimbursements',
  [PayoutType.PrepaidBonus]: 'Prepaid Bonus',
  [PayoutType.Reimbursements]: 'Reimbursements',
  [PayoutType.PrepaidExpense]: 'Prepaid Expese',
  [PayoutType.PrepaidAdvance]: 'Prepaid Advance',
  [PayoutType.NegativeEarnings]: 'Negative Commission',
  [PayoutType.NegativeAdjustment]: 'Negative Adjustemnt',
  [PayoutType.PrepaidReimbursements]: 'Prepaid Reimbursements',
};
