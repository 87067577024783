import { components, DropdownIndicatorProps } from 'react-select';

import { Clock } from './styles';

export const TimeDropdownIndicator = (props: DropdownIndicatorProps<any>) => {
  return components.DropdownIndicator({
    ...props,
    children: <Clock disabled={props.selectProps.isDisabled} />,
  });
};
