import { useState, type JSX, useEffect } from 'react';

import { GoToPageForm } from '../go-to-page-form';

import { PaginationMobileProps } from './types';

const PaginationMobile = ({
  currentPage,
  prevPageButton,
  nextPageButton,
  forceMobileView,
  onGoToPageChange,
  onGoToPageSubmit,
}: PaginationMobileProps): JSX.Element => {
  const [goToPageValue, setGoToPageValue] = useState(String(currentPage));

  useEffect(() => setGoToPageValue(String(currentPage)), [currentPage]);

  return (
    <>
      {prevPageButton}
      <GoToPageForm
        value={goToPageValue}
        forceMobileView={forceMobileView}
        onSubmit={(e) => onGoToPageSubmit(e, goToPageValue)}
        onChange={(e) => onGoToPageChange(e, setGoToPageValue)}
      />
      {nextPageButton}
    </>
  );
};

export { PaginationMobile };
