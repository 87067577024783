import { Formik } from 'formik';

import { useAlert } from 'hooks';
import { ButtonColors } from 'types';
import { useListQuery } from 'store/api/category';
import { acceptPdfAndImageFileTypes } from 'config';
import { useListQuery as usePaymentMethodsQuery } from 'store/api/payment-methods';
import {
  handleFormSubmitWithFiles,
  makeSelectOptionsFromEntities,
} from 'utils/helpers';
import {
  Row,
  Col,
  Dialog,
  Button,
  InputField,
  DialogType,
  SelectField,
  ButtonTypes,
  CommonStyles,
  DropZoneContent,
  FileDropzoneField,
} from 'components';

import { FormProps, FormValues } from './types';
import { getInitialValues, validationSchema } from './config';

export const Form = ({ data, title, isEdit, trigger, onSubmit }: FormProps) => {
  const { handleError } = useAlert();
  const { isLoading, data: categories } = useListQuery();
  const { data: paymentMethods, isLoading: isPaymentMethodLoading } =
    usePaymentMethodsQuery({ status: 'active' });

  const categoryOptions = makeSelectOptionsFromEntities(categories);
  const paymentMethodOptions = makeSelectOptionsFromEntities(paymentMethods);

  return (
    <Dialog title={title} trigger={trigger} closeOnDocumentClick={false}>
      {(close, _, ButtonWrap) => (
        <Formik<FormValues>
          validationSchema={validationSchema}
          initialValues={getInitialValues(data)}
          onSubmit={handleFormSubmitWithFiles({
            data,
            handleError,
            onSubmit: async (values) => {
              await onSubmit(values);
              close();
            },
            files: [
              {
                formFieldName: 'receipt',
                dataFieldName: 'receiptUrl',
                requestFieldName: 'receiptUrl',
              },
            ],
          })}
        >
          {({ dirty, values, isSubmitting }) => (
            <CommonStyles.Form>
              <Row>
                <Col>
                  <InputField
                    number
                    decimal
                    allowNegative
                    name="amount"
                    label="Amount"
                    placeholder="Amount"
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <SelectField
                    onlyValue
                    label="Category"
                    name="categoryId"
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    options={categoryOptions}
                    placeholder="Select category"
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <SelectField
                    onlyValue
                    isLoading={isLoading}
                    name="paymentMethodId"
                    label="Payment Method"
                    options={paymentMethodOptions}
                    placeholder="Select payment method"
                    isDisabled={isPaymentMethodLoading}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <InputField
                    name="notes"
                    label="Notes"
                    type="textarea"
                    placeholder="Notes"
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <FileDropzoneField
                    errorMessage
                    name="receipt"
                    label="Upload receipt"
                    accept={acceptPdfAndImageFileTypes}
                  >
                    <DropZoneContent file={values?.receipt} />
                  </FileDropzoneField>
                </Col>
              </Row>

              <ButtonWrap type={DialogType.Plain}>
                <Button
                  text="Cancel"
                  onClick={close}
                  colorType={ButtonColors.LightBlue}
                />
                <Button
                  type={ButtonTypes.Submit}
                  disabled={!dirty || isSubmitting}
                  text={isEdit ? 'Edit' : 'Create'}
                />
              </ButtonWrap>
            </CommonStyles.Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
