import { useMemo, useEffect, ComponentType } from 'react';

import { Select } from 'components/select';
import { TimeDropdownIndicator } from 'components/select/components';

import { getOptions } from './helpers';
import { TimePickerProps } from './types';

export const TimePicker = ({
  value,
  onChange,
  minValue,
  step = 15,
  components,
  isSearchable = true,
  setDefaultSelectedValue,
  ...props
}: TimePickerProps) => {
  const options = useMemo(() => getOptions(step, minValue), [step, minValue]);

  const selectedValue = useMemo(() => {
    if (!value && value !== 0) {
      return undefined;
    }
    const sorted = [...options];
    sorted.sort(({ value: a }, { value: b }) => {
      const aDiff = Math.abs(Number(a) - value);
      const bDiff = Math.abs(Number(b) - value);
      if (aDiff === bDiff) {
        return 0;
      }
      return aDiff > bDiff ? 1 : -1;
    });
    return sorted[0];
  }, [options, value]);

  useEffect(() => {
    const checkedValue = Number(selectedValue?.value);

    if (
      selectedValue &&
      setDefaultSelectedValue &&
      typeof value === 'number' &&
      checkedValue !== value
    ) {
      onChange(checkedValue);
    }
  }, [selectedValue?.value]);

  return (
    <Select
      defaultControl
      options={options}
      value={selectedValue}
      isSearchable={isSearchable}
      components={{
        DropdownIndicator: TimeDropdownIndicator as ComponentType<any>,
        ...components,
      }}
      onChange={(newValue) => {
        // @ts-ignore
        const result = newValue?.value;
        onChange(result ? Number(result) : undefined);
      }}
      {...props}
    />
  );
};

export type { TimePickerProps };
