import { type JSX, PropsWithChildren } from 'react';

import { Icon } from 'components';

import * as Styles from './styles';

export interface LoaderProps {
  isLoading?: boolean;
  isFullScreen?: boolean;
  absolutePosition?: boolean;
}

export const Loader = ({
  children,
  isLoading = false,
  isFullScreen = true,
  absolutePosition = false,
}: PropsWithChildren<LoaderProps>): JSX.Element => (
  <>
    {children}
    {isLoading && (
      <Styles.Container
        data-testid="loader"
        isFullScreen={isFullScreen}
        absolutePosition={absolutePosition}
      >
        <Icon.Loader width={128} height={128} />
      </Styles.Container>
    )}
  </>
);
