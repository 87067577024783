import { components, IndicatorsContainerProps } from 'react-select';

import { ButtonStyleTypes } from 'types';
import { Button } from 'components/button';
import { isMobile as isMobileHook } from 'hooks';

export const IndicatorsContainer = ({
  children,
  ...props
}: IndicatorsContainerProps) => {
  const isMobile = isMobileHook();

  const {
    value,
    isOverflow,
    showAllValues,
    onShowMoreClick,
    maxVisibleValues,
  } = props.selectProps;

  return components.IndicatorsContainer({
    ...props,
    children: (
      <>
        {isOverflow &&
          !isMobile &&
          maxVisibleValues &&
          Array.isArray(value) && (
            <Button
              transparent
              padding="0"
              onClick={onShowMoreClick}
              styleType={ButtonStyleTypes.Ghost}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
              text={
                showAllValues
                  ? 'Hide'
                  : `Show more (${value.length - maxVisibleValues})`
              }
            />
          )}
        {children}
      </>
    ),
  });
};
