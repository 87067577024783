import { withRef } from 'hoc';

import { AddButton } from './add-button';
import { EditButton } from './edit-button';
import { CloseButton } from './close-button';
import { DeleteButton } from './delete-button';
import { DownloadButton } from './download-button';

export { BackButton } from './back-button';
export { ResetButton } from './reset-button';
export { CloseButton } from './close-button';
export { DownloadButton } from './download-button';
export { RowFormButtons } from './row-form-buttons';

const AddButtonWithRef = withRef(AddButton);
const EditButtonWithRef = withRef(EditButton);
const CloseButtonWithRef = withRef(CloseButton);
const DeleteButtonWithRef = withRef(DeleteButton);
const DownloadButtonWithRef = withRef(DownloadButton);

export {
  AddButton,
  EditButton,
  DeleteButton,
  AddButtonWithRef,
  EditButtonWithRef,
  CloseButtonWithRef,
  DeleteButtonWithRef,
  DownloadButtonWithRef,
};
