import { FieldMetaProps } from 'formik';

import { Messages } from 'config';

export const parseAxiosError = (error: any) =>
  error?.data?.message || error?.response?.data?.message || error.message;

export const getErrorMessage = (error: any) => {
  if (typeof error === 'string') {
    return error;
  }

  if ('data' in error) {
    if ('error' in error.data && typeof error.data.error === 'string') {
      return error.data.error;
    }

    if ('errors' in error.data && Array.isArray(error.data.errors)) {
      if ('message' in error.data.errors[0]) {
        return error.data.errors[0].message;
      }

      return error.data.errors[0];
    }
  }

  return (
    error?.response?.data?.error?.msg ||
    error?.response?.data?.debug?.message ||
    error?.message ||
    Messages.SOMETHING_WENT_WRONG
  );
};

export const controlError = <T = any>(
  meta: FieldMetaProps<T>,
  name: string,
  label?: string,
  controlErrorLabel?: boolean
) => {
  const error = meta && meta.touched && meta.error;
  if (!error || typeof error !== 'string') {
    return undefined;
  }
  return controlErrorLabel ? error.replace(name, label || 'Value') : error;
};
