import * as Yup from 'yup';

import { EventExpense } from 'types';
import { getUploadedFile } from 'utils/helpers';

import { FormValues } from './types';

export const getInitialValues = (data?: EventExpense): FormValues =>
  data
    ? {
        ...data,
        receipt: getUploadedFile(data.receiptUrl),
      }
    : {
        notes: '',
        amount: '',
        receipt: undefined,
        categoryId: undefined,
        paymentMethodId: undefined,
      };

export const validationSchema = Yup.object().shape({
  categoryId: Yup.number().required(),
  notes: Yup.string().min(6).required(),
  paymentMethodId: Yup.number().required(),
  receipt: Yup.object().required('File is a required field'),
  amount: Yup.number().required().typeError('Amount must be a valid number'),
});
