import { useMemo, type JSX } from 'react';

import { mapQuery } from 'utils/query';
import { formatPrice } from 'utils/helpers';
import { isMobile, isDesktop } from 'hooks';
import { ListCardStyle, EventInventory } from 'types';
import { useInventoryStatisticQuery } from 'store/api/event';
import { Flex, List, ListCard, ListHeader } from 'components';

import * as Styles from './styles';
import { InventoryProps } from './types';
import { endTitles, soldTitles, startTitles } from './config';

const Card = ({
  title,
  children,
}: {
  title: EventInventory;
  children: JSX.Element;
}) => (
  <Flex gap={16} fullWidth flexDirection="column">
    <Styles.Title title={title}>
      <h3>{title}</h3>
    </Styles.Title>
    {children}
  </Flex>
);

export const Inventory = ({ event }: InventoryProps) => {
  const mobile = isMobile();
  const desktop = isDesktop();
  const { data, ...props } = useInventoryStatisticQuery(String(event.id), {
    skip: !event.id,
  });

  const totalSold = useMemo(
    () =>
      formatPrice(
        formatPrice(
          data?.sold?.reduce((acc, value) => acc + +value.totalPrice, 0)
        )
      ),
    [data?.sold]
  );

  return (
    <Flex gap={24} flexDirection="column">
      <Flex gap={24} fullWidth flexDirection={mobile ? 'column' : 'row'}>
        <Card title={EventInventory.Start}>
          <List
            {...mapQuery(props)}
            count={data?.start?.length}
            mainWrapperStyles={{ padding: 0 }}
            header={<ListHeader padding="8px" titles={startTitles} />}
          >
            {data?.start?.map((value, i) => (
              <ListCard
                key={i}
                headers={startTitles}
                values={[
                  value?.name,
                  value?.masters,
                  value?.singles,
                  value?.total,
                ]}
              />
            ))}
          </List>
        </Card>

        <Card title={EventInventory.End}>
          <List
            {...mapQuery(props)}
            count={data?.end?.length}
            mainWrapperStyles={{ padding: 0 }}
            header={<ListHeader padding="8px" titles={endTitles} />}
          >
            {data?.end?.map((value, i) => (
              <ListCard
                key={i}
                headers={endTitles}
                values={[
                  value?.name,
                  value?.masters,
                  value?.singles,
                  value?.demos,
                  value?.damaged,
                  value?.total,
                ]}
              />
            ))}
          </List>
        </Card>
      </Flex>

      <Card title={EventInventory.Sold}>
        <List
          {...mapQuery(props)}
          count={data?.sold?.length}
          mainWrapperStyles={{ padding: 0 }}
          header={<ListHeader padding="8px" titles={soldTitles} />}
          footer={
            <ListCard
              isOdd
              odd={ListCardStyle.Grey}
              tabletTemplate="1fr 1fr"
              template="repeat(5, 1fr)"
              values={
                desktop
                  ? [<p>Total Stock Cost</p>, '', '', '', totalSold]
                  : [<p>Total Stock Price</p>, totalSold]
              }
            />
          }
        >
          {data?.sold?.map((value, i) => (
            <ListCard
              key={i}
              headers={soldTitles}
              values={[
                value?.name,
                value?.productRevenue && `${value.productRevenue}%`,
                value?.quantity,
                formatPrice(value?.price),
                formatPrice(value?.totalPrice),
              ]}
            />
          ))}
        </List>
      </Card>
    </Flex>
  );
};
