import { Navigate } from 'react-router-dom';
import { Ref, PropsWithChildren } from 'react';

import { withRef } from 'hoc';
import { Loader } from 'components/loader';
import { RetryRequest } from 'components/retry-request';

import * as Styles from './styles';
import { DetailsProps } from './types';

const Details = ({
  error,
  isError,
  refetch,
  children,
  isLoading,
  refForward,
  loaderAbsolutePosition,
  notFoundPath = '/not-found',
  ...props
}: PropsWithChildren<DetailsProps>) => {
  const getMainElement = () => {
    if (isLoading) {
      return (
        <Loader
          isLoading
          isFullScreen={false}
          absolutePosition={loaderAbsolutePosition}
        />
      );
    } else if (isError) {
      return <RetryRequest error={error} refetch={refetch} />;
    } else if (children) {
      return <Styles.Main>{children}</Styles.Main>;
    }
    if (localStorage.getItem('allow-404')) {
      return null;
    }
    return <Navigate to={notFoundPath} />;
  };

  return (
    <Styles.Container ref={refForward as Ref<HTMLDivElement>} {...props}>
      {getMainElement()}
    </Styles.Container>
  );
};

export const DetailsWithRef = withRef(Details);

export { Details };
