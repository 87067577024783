import type { JSX } from 'react';

import { useValidationByRules } from 'hooks';
import { BadgeColor, BadgeBorderRadius } from 'types';

import { Badge, Badges } from '../../badge';

import { FieldValidationRulesProps } from './types';

export const FieldValidationRules = ({
  name,
  rules,
}: FieldValidationRulesProps): JSX.Element => {
  const validationResult = useValidationByRules(name, rules);

  return (
    <Badges>
      {Object.keys(rules).map((ruleName) => (
        <Badge
          smallFont
          key={ruleName}
          text={ruleName}
          borderRadius={BadgeBorderRadius.Small}
          styleType={
            validationResult[ruleName] ? BadgeColor.Green : BadgeColor.LightGrey
          }
        />
      ))}
    </Badges>
  );
};
