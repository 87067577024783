// import { useMemo, ReactNode } from 'react';
// // import { AlertInstance, useAlert as useReactAlert } from 'react-alert';

// import { AlertOptionsType } from 'types';
// // import { getErrorMessage } from 'utils/error';

// export const useAlert = () => {
//   const alert = useReactAlert();

//   const data = useMemo(() => {
//     const handleError = (error: any): AlertInstance =>
//       alert.error(getErrorMessage(error), { timeout: 30000 });

//     const showSuccessAlert = (
//       message?: ReactNode,
//       options?: AlertOptionsType
//     ): AlertInstance => alert.success(message, options);

//     const showErrorAlert = (
//       message?: ReactNode,
//       options?: AlertOptionsType
//     ): AlertInstance => alert.error(message, { timeout: 30000, ...options });

//     return { handleError, showErrorAlert, showSuccessAlert };
//   }, [alert]);

//   const data = useMemo(() => {
//     const handleError = (error: any) => {
//       console.log('error', error);
//     };

//     const showSuccessAlert = (
//       message?: ReactNode,
//       options?: AlertOptionsType
//     ) => {
//       console.log('message', message);
//       console.log('options', options);
//     };

//     const showErrorAlert = (
//       message?: ReactNode,
//       options?: AlertOptionsType
//     ) => {
//       console.log('message', message);
//       console.log('options', options);
//     };

//     return { handleError, showErrorAlert, showSuccessAlert };
//   }, []);

//   return data;
// };

import { useMemo } from 'react';
import { toast } from 'react-toastify';

import { Alert } from 'components/alert';
import { getErrorMessage } from 'utils/error';
import type { AlertOptionsType } from 'types';

export const useAlert = () => {
  const showAlert = (message: string, options: AlertOptionsType) => {
    return toast(Alert, { data: { message, options } });
  };

  const handleError = (error: any) =>
    showAlert(getErrorMessage(error), { type: 'error' });

  const showSuccessAlert = (message: string, options?: AlertOptionsType) =>
    showAlert(message, { type: 'success', ...options });

  const showErrorAlert = (message: string, options?: AlertOptionsType) =>
    showAlert(message, { type: 'error', ...options });

  return useMemo(
    () => ({
      handleError,
      showErrorAlert,
      showSuccessAlert,
    }),
    []
  );
};
