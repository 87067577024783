import { useField } from 'formik';

import { CommonFieldProps } from 'types';
import { controlError } from 'utils/error';
import { ErrorMessage } from 'components/error-message';

import { TimePicker, TimePickerProps } from '../time-picker';

export interface TimePickerFieldProps
  extends Omit<TimePickerProps, 'name' | 'onChange'>,
    CommonFieldProps {}

export const TimePickerField = ({
  name,
  label,
  controlErrorLabel = true,
  ...props
}: TimePickerFieldProps) => {
  const [field, meta, helpers] = useField(name);

  const error = controlError(meta, name, label, controlErrorLabel);

  return (
    <div>
      <TimePicker
        label={label}
        style={{ minWidth: '140px' }}
        {...props}
        {...field}
        onChange={helpers.setValue}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};
